@import '../../../../colors';

.prescription-details-view {
  background-color: #F2EDF0;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;

  .ant-btn {
    border-radius: 20px;
  }

  .subscription-info {
    background: #fff1cf;
    border-radius: 10px;
    font-size: 14px;
    line-height: 21px;
    margin: 15px 0;
    padding: 24px;
    max-width: 550px;
  }

  .prescription-details-header {
    height: fit-content;
    display: flex;
    border-bottom: 1px solid #061846;
    width: 100%;
    padding: 0;
    min-height: 89px;
    flex-flow: wrap;

    & a {
      height: fit-content;
      align-self: center;
    }

    .ant-row {
      width: 100%;
    }

    .ant-col {
      display: flex;
      height: 100%;
    }

    .prescription-details-header-buttons {
      margin-left: auto;
      display: flex;
      align-items: center;
    }

    .ant-btn {
      align-self: center;
      height: 36px;
    }

    .go-back-btn {
      color: $dirty-purple;
      font-family: 'ABCSocialExtended-Regular', sans-serif;
      font-size: 16px;
      font-weight: 500;
      border: transparent;
      &:hover, &:focus {
        border: transparent;
      }
    }

    .btn-right {
      font-family: Migrena_Grotesque;
      font-size: 14px;
      font-weight: 500;
      line-height: 30px;
      min-height: 36px;
      min-width: 120px;

      span {
        padding-bottom: 2px;
      }
    }

    .btn-buy {
      @extend .btn-right;

      span {
        margin-left: 5px;
      }
    }

    .btn-edit {
      @extend .btn-right;
      margin-right: 25px;
    }

    @media (max-width: 512px) {
      .ant-col {
        flex-direction: column;
      }

      .prescription-details-header-buttons {
        width: 100%;
      }

      .go-back-btn {
        width: 100%;
        text-align: left;
      }

      .btn-right {
        width: 100%;
        margin: 10px 0 10px 25px;
      }
    }

    .ant-divider {
      color: $dusty-pink;
      margin: 0 0 20px;
    }
  }

  .prescription-info {
    margin-bottom: 30px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;

    h1,
    h4,
    h5 {
      color: $dirty-purple;
      font-family: 'ABCSocialExtended-Medium', sans-serif;
      font-size: 16px;
      line-height: 28px;
      font-weight: 600;
    }

    h1 {
      font-size: 28px;
      margin: 20px 0;
    }

    h5 {
      font-weight: 400;
    }

    .prescription-info-warning {
      color: $red;
      margin-top: 15px;
    }
  }

  .prescription-details-prefs-legend {
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;

    .prescription-preferences-box {
      max-width: 550px;
    }

    @media (max-width: 800px) {
      flex-direction: column;

      .prescription-preferences-box {
        max-width: 100%;
        width: 100%;
      }
    }
  }
}
