@import '../../colors';

.await-contact {
  border-radius: 4px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
  background-color: #fdfbf8;
  display: flex;
  align-items: center;
  padding: 36px 30px;

  @media (max-width: 32em) {
    padding: 24px 20px;
    flex-direction: column;
  }

  .await-contact-header {
    width: 100%;
    text-align: center;

    @media (max-width: 32em) {
      text-align: center;
      margin: auto auto 12px;
    }

    b {
      font-size: 20px;
      font-weight: 600;
      color: $dirty-purple;
      display: inline-block;
      margin-bottom: 18px;
    }

    p {
      font-size: 15px;
      line-height: 1.6;
      font-family: 'Lato';
      text-align: center;
    }
  }
}
