@import './fonts.scss';

body {
  font-family: 'ABCSocialExtended-Medium', sans-serif !important;
  background-color: #F2EDF0;
}

.center-horizontal {
  margin: 0 auto;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #f9f9f9;
}

::-webkit-scrollbar-thumb {
  background: #564257;
}

.font-weight-black {
  font-weight: 900;
}

.button-underline {
  & > * {
    text-decoration: underline solid;
  }
}

.button-no-padding-link {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.hide-on-desktop {
  @media (min-width: 512px) {
    display: none;
  }
}

.hide-on-mobile {
  @media (max-width: 512px) {
    display: none;
  }
}
