.certificates-inline {
  padding: 0 0 40px;
  text-align: center;

  .certificates-inline-text {
    opacity: 0.7;
    color: black;
    font-family: Lato;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 18px;
  }

  img {
    margin-top: 10px;
    max-width: 250px;
  }
}
