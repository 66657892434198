* {
  word-spacing: normal!important;
  letter-spacing: normal;
  font-feature-settings: normal!important;
  -webkit-font-feature-settings: normal!important;
  -moz-font-feature-settings: normal!important;
  font-variant: normal!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: ABCSocialExtended-Medium;
  src: local(ABCSocialExtended-Medium),
  url("./assets/fonts/SocialExtended/ABCSocialExtended-Medium.otf") format("opentype");
  src: local(ABCSocialExtended-Medium),
  url("./assets/fonts/SocialExtended/ABCSocialExtended-Medium.woff") format("woff");
  src: local(ABCSocialExtended-Medium),
  url("./assets/fonts/SocialExtended/ABCSocialExtended-Medium.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: ABCSocialExtended-Regular;
  src: local(ABCSocialExtended-Medium),
  url("./assets/fonts/SocialExtended/ABCSocialExtended-Regular.otf") format("opentype");
  src: local(ABCSocialExtended-Medium),
  url("./assets/fonts/SocialExtended/ABCSocialExtended-Regular.woff") format("woff");
  src: local(ABCSocialExtended-Medium),
  url("./assets/fonts/SocialExtended/ABCSocialExtended-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: ABCSocial-Medium;
  src: local(ABCSocial-Medium),
  url("./assets/fonts/Social/ABCSocial-Medium.otf") format("opentype");
  src: local(ABCSocial-Medium),
  url("./assets/fonts/Social/ABCSocial-Medium.woff") format("woff");
  src: local(ABCSocial-Medium),
  url("./assets/fonts/Social/ABCSocial-Medium.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: ABCSocial-Regular;
  src: local(ABCSocial-Regular),
  url("./assets/fonts/Social/ABCSocial-Regular.otf") format("opentype");
  src: local(ABCSocial-Regular),
  url("./assets/fonts/Social/ABCSocial-Regular.woff") format("woff");
  src: local(ABCSocial-Regular),
  url("./assets/fonts/Social/ABCSocial-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: ABCSocialMono-Bold;
  src: local(ABCSocialMono-Bold),
  url("./assets/fonts/SocialMono/ABCSocialMono-Bold.otf") format("opentype");
  src: local(ABCSocialMono-Bold),
  url("./assets/fonts/SocialMono/ABCSocialMono-Bold.woff") format("woff");
  src: local(ABCSocialMono-Bold),
  url("./assets/fonts/SocialMono/ABCSocialMono-Bold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
