@import '../../../../colors';

.dashboard-wrapper {
  padding: 20px;
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
}

.content-dashboard-wrapper {
  width: 100%;
  margin-bottom: 44px;
  opacity: 0;
  transition: 333ms ease all;

  @media (max-width: 32em) {
    max-width: 100%;
  }

  a {
    align-self: flex-end;
    align-content: center;
    justify-content: center;

    @media (max-width: 650px) {
      width: 100%;
      margin-top: 10px;
      align-self: center;
    }

    .ant-btn-primary {
      border-radius: 16px;
      font-size: 14px;
      line-height: 20px;
      color: #fff;
      border-color: transparent;

      text-align: center;
      height: 48px;

      background: $dusty-orange;
    }
  }
}

.calendar-wrapper {
  opacity: 0;
  transition: 333ms ease all;
}

.modal-header-text {
  font-family: 'ABCSocialExtended-Medium', sans-serif;
  color: $dusty-orange;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
}

.dietitian-container {
  height: 100%;
  background-color: white;
  border-radius: 16px;
  padding: 40px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  p.dietitian-p {
    font-family: ABCSocial-Regular, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    /* or 185% */

    display: flex;
    align-items: center;

    color: $dusty-orange;
    max-width: 300px;
    a {
      color: #67728E;
      margin-left: 5px;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .dietitian-meeting {
    max-width: 200px;
    margin-top: 16px;
    background: #d8f0cd;
    border-radius: 10px;
    padding: 6px 12px;
  }

  .dietitian-info {
    max-width: 300px;

    strong {
      font-family: 'ABCSocialExtended-Medium', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;

      display: flex;
      align-items: center;

      color: $dusty-orange;
      margin-bottom: 15px;
    }

    button {
      margin-top: 15px;
      padding: 10px;
      background-color: #061846;
      color: #fff;
      border-color: transparent;
      height: 45px;
      min-height: 45px;
      font-size: 14px;
      line-height: 20px;
      border-radius: 16px;
      font-family: ABCSocial-Regular, sans-serif;

      &:hover {
        color: #fff;
      }
    }
  }

  .dietitian-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 40px 0 40px 30px;
    border-left: 1px solid #f1f1f1;

    img {
      width: 80px;
      height: 80px;
      margin-bottom: 10px;
    }
  }
}

.blood-info {
  color: #061846;

  strong {
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 28px;
    font-family: 'ABCSocialExtended-Medium', sans-serif;

    span {
      display: flex;
      align-content: center;
      padding: 0 8px;
      font-weight: normal;
      font-size: 12px;
      line-height: 24px;
      margin-left: 15px;
      background: green;
      color: white;
      border-radius: 4px;
    }
  }

  border-radius: 16px;
  background-color: white;
  padding: 40px;
  align-items: center;

  .blood-info-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 15px 0 -25px 0;
    color: #061846;

    p {
      color: #061846;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 20px;
      max-width: 250px;
      font-family: ABCSocial-Regular, sans-serif;
    }

    img {
      width: 95px;
      height: 110px;
      margin-top: -30px;
    }
  }

  button {
    max-width: 150px;
  }

  .act {
    padding: 10px 16px;
    background-color: #061846;
    color: #fff;
    border-radius: 12px;
    height: 45px;
    border-color: transparent;
    &:hover, &:focus, &:visited {
      color: #fff;
    }
  }

  @media (max-width: 768px) {
    padding: 15px 15px 25px;

    .blood-info-container {
      margin: 20px 0 -20px 0;

      img {
        width: 67px;
      }
    }
  }
}

.status-container {
  border-radius: 16px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 40px;
  align-items: center;

  @media (max-width: 768px) {
    padding: 15px 15px 25px;
  }

  &.failed {
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  strong {
    display: block;
    color: $dirty-purple;
    font-size: 18px;
    line-height: 28px;
    font-family: 'ABCSocialExtended-Medium', sans-serif;
    margin-bottom: 20px;
  }

  .status-badge-container {
    display: flex;
    @media (min-width: 769px) {
      min-width: 200px;
    }

    .status-badge {
      font-family: ABCSocial-Regular, sans-serif;
      font-size: 16px;
      border-radius: 16px;
      flex-grow: 1;
      text-align: center;
      font-weight: 600;
      color: $snow-white;
    }

    .info-icon {
      padding: 5px;
      margin: 0 10px;
    }
  }

  .status-icon {
    padding: 0;
  }
}

.status-icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    transform: scale(0.6);
    width: 67px;
  }

  &.failed {
    align-self: flex-end;
    padding-left: 10px;

    .ant-btn {
      padding: 0 25px;
      width: 100%;
    }

    @media (max-width: 768px) {
      align-self: normal;
      width: 100%;
      padding: 0;
      transform: none;
    }
  }
}

.status-popover-container {
  h3 {
    font-family: ABCSocialExtended-Medium, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
  }

  padding: 25px;
  font-family: ABCSocial-Regular, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
}
