@import '../../../colors';

.ant-alert {
  margin-top: 15px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 10px;
  border: none;

  font-family: Metropolis;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;

  .ant-alert-icon {
    top: unset;
  }
}

.ant-alert.ant-alert-no-icon {
  padding: 16px;
}

.ant-alert-error {
  background: $light-red-1;
  .ant-alert-message {
    color: $dark-red;
  }
  .ant-alert-icon {
    color: $dark-red;
  }
}
