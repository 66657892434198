@import '../../../colors';

.collapse-prescription {
  .sundose-collapse-header {
    align-items: center;
  }

  .ant-collapse-extra {
    & > div {
      display: flex;
      align-items: center;
    }
  }

  .icon {
    &.icon-chevron {
      color: $dirty-purple;
      display: flex;
      position: absolute;
      left: 12px;
      margin-top: 8px;
    }
  }
}

.collapse-prescription-dietitian {
  .ant-collapse-header,
  .sundose-collapse-header {
    background-color: $light-blue;

    .more-button {
      &.ant-popover-open,
      &:hover,
      &:focus {
        background-color: $dark-blue;
      }
    }
  }

  .ant-collapse-content-box,
  .prescription-collapse-body {
    background-color: $very-light-blue;
  }
}

.prescription-collapse-body {
  font-family: ABCSocial-Regular, sans-serif;

  .prescription-price,
  .prescription-author {
    font-weight: normal;
    font-size: 16px;
    margin-bottom: 15px;
  }

  .custom-tag-style {
    margin-left: 15px;
  }

  .prescription-action-buttons {
    .no-padding {
      padding: 0;
    }

    button {
      font-size: 16px;
      font-weight: 600;
      font-family: Lato;
      line-height: 19px;

      i {
        font-size: 12px;
        top: -1px;
        position: relative;
        margin-right: 10px;
      }
    }
  }
}

.sundose-collapse-header {
  display: flex;

  .collapse-header-container {
    width: 100%;
    align-self: flex-start;
    flex: 1;

    .header-title {
      white-space: normal;
      word-break: break-word;
    }
  }
}

.collapse-header-buttons {
  font-size: 14px;
  font-family: 'Migrena_Grotesque';
  font-weight: 500;
  width: fit-content;
  display: flex;
  flex: 2;
  justify-content: flex-end;

  a {
    color: white !important;
    font-weight: 500 !important;
    height: 100%;
    display: flex;
    align-items: center;
    white-space: pre-line;
    padding: {
      top: 4px;
    }
  }
}

.ant-collapse-extra {
  display: flex;

  .more-button {
    color: $dirty-purple;
    margin-left: 5px!important;
    display: flex!important;
    align-items: center!important;
    margin-right: -22px!important;

    border: transparent!important;
    background-color: transparent!important;
    svg {
      margin: 0 auto;
    }
  }
}

.custom-icon-button-link {
  img {
    position: relative;
    top: 1px;
    margin-right: 5px;
  }
}

.collapse-prescription-extra-popover {
  padding-top: 0 !important;

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner-content {
    padding: 10px;
  }
}

.prescription-more-menu-buttons {
  font-family: 'ABCSocialExtended-Regular', sans-serif;
  font-weight: normal!important;
  font-size: 14px;
  line-height: 17px;
  background-color: transparent;
  border: transparent;

  button {
    display: flex;
    align-items: center;
    color: $dusty-orange;
    font-weight: normal;
    width: 100%;

    &:hover,
    &:active,
    &:focus {
      background-color: $very-light-gray;
      color: $dusty-orange;
    }

    .icon {
      margin-right: 10px;
      position: relative;
      top: 1px;
      width: 16px;
    }
  }
}
