@import '../../colors';

.order-status {
  display: block;
  border-radius: 16px;
  font-family: ABCSocial-Regular, sans-serif;
  font-size: 12px;
  font-weight: bold;
  padding: 16px;
  text-align: center;
  color: white;

  &.order-pending {
    &.status-badge {
      background-color: $dark-yellow;
    }

    &.status-icon {
      &::before {
        content: url('../assets/img/order_status_icons/order-pending.svg');
      }
    }
  }

  &.order-processing {
    &.status-badge {
      background-color: $dark-green;
    }

    &.status-icon {
      &::before {
        content: url('../assets/img/order_status_icons/order-processing.svg');
      }
    }
  }

  &.order-completed {
    &.status-badge {
      background-color: $dark-green;
    }

    &.status-icon {
      &::before {
        content: url('../assets/img/order_status_icons/order-completed.svg');
      }
    }
  }

  &.order-cancelled {
    &.status-badge {
      background-color: $light-gray-3;
    }
  }

  &.order-returned-to-sender {
    &.status-badge {
      background-color: $light-gray-3;
    }
  }

  &.order-refunded {
    &.status-badge {
      background-color: $light-gray-3;
    }
  }

  &.order-failed {
    &.status-badge {
      background-color: $error-message;
    }
  }

  &.order-waiting-for-courier {
    &.status-badge {
      background-color: $dark-green;
    }

    &.status-icon {
      &::before {
        content: url('../assets/img/order_status_icons/order-waiting-for-courier.svg');
      }
    }
  }

  &.order-ready-to-pickup {
    &.status-badge {
      background-color: $dark-green;
    }

    &.status-icon {
      &::before {
        content: url('../assets/img/order_status_icons/order-waiting-for-courier.svg');
      }
    }
  }

  &.order-waiting-for-courier {
    &.status-badge {
      background-color: $dark-green;
    }

    &.status-icon {
      &::before {
        content: url('../assets/img/order_status_icons/order-waiting-for-courier.svg');
      }
    }
  }

  &.order-delivered {
    &.status-badge {
      background-color: $very-light-green;
      color: $dark-green;
    }

    &.status-icon {
      &::before {
        content: url('../assets/img/order_status_icons/order-delivered.svg');
      }
    }
  }

  &.order-production {
    &.status-badge {
      background-color: $dark-green;
    }

    &.status-icon {
      &::before {
        content: url('../assets/img/order_status_icons/order-production.svg');
      }
    }
  }
}
