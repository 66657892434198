@import '../../colors';

.modal-backdrop {
  border-radius: 4px;
  -webkit-backdrop-filter: blur(24px);
  backdrop-filter: blur(24px);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.modal-close-button {
  width: 36px;
  height: 36px;
  border-radius: 18px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  font-size: 10px;
  color: $dirty-purple;
  position: fixed;
  top: 40px;
  right: 40px;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}

.modal-content {
  position: relative;
}
