$dusty-orange: #061846;
$dirty-purple: #061846;
$very-light-pink: #faf9f9;
$dark-cream: #f9ef95;
$very-light-pink-two: #fcfaf7;
$very-light-pink-three: #bbbbbb;
$very-light-pink-four: #cccccc;

$very-light-dirty-purple: #6d556d;
$gray: #dddddd;

$snow-white: #ffffff;
$dusty-pink: #c9c2c8;
$light-dusty-pink: #a99fa9;

$light-gray: #d7d1d6;
$light-gray-2: #c2c2c2;
$light-gray-3: #979797;
$dark-gray: #2a2928;
$summary-box-color: #fafafa;

$country-norms-description-color: #aaa0ab;
$premix-substance-ingredient: #9f949f;
$premix-price-content: #f9f9f9;

$very-light-yellow: #F2EDF0;
$light-yellow: #f9f3ea;
$dark-yellow: #eddcc4;

$collapse-header: #f3e7d6;

$very-light-gray: #f5f5f5;

$red: #d81111;
$error-message: #ec2a18;
$red-2: #f05447;
$light-red-1: #ffd7cb;
$dark-red: #aa0606;

$green: #3ca25a;
$lush-green: #6a9f2f;
$light-green: #2da562;
$light-green-2: #d3f1ce;
$very-light-green: #e6f2de;
$notification-success: #3dbd7d;
$order-completed-color: #43b565;
$dark-green: #008b44;

$light-blue: #b4ddf4;
$very-light-blue: #eaf8fd;
$dark-blue: #7fbfe9;

$light-beige: #F2EDF0;
$light-yellow-1: #ffe3a8;
$dark-yellow: #fbb122;

$accent-color: #061846;
