@import '../../../colors';

.settings-navigation-accordion {
  text-align: left;
  width: 100%;

  .settings-navigation-title {
    color: $dirty-purple;
    font-family: 'ABCSocialExtended-Medium', sans-serif;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }

  .ant-collapse-header {
    color: $dirty-purple !important;
    font-family: 'ABCSocialExtended-Regular', sans-serif;
    font-size: 22px;
    font-weight: bold;
    height: 60px;
    display: flex;
    align-items: center;
    padding-left: 20px !important;
    background-color: $snow-white;
  }

  .ant-collapse {
    border-radius: 0;

    .ant-collapse-item {
      border-bottom: 1px solid $very-light-pink-four;
    }

    :last-child {
      border-bottom: none;
    }
  }

  @media (max-width: 512px) {
    .settings-navigation-title {
      font-size: 24px;
    }
    .ant-collapse-header {
      font-size: 17px;
    }

    .settings-navigation-title {
      padding-left: 20px;
    }

    .ant-collapse {
      border-bottom: 1px solid $very-light-pink-four;
      border-top: 1px solid $very-light-pink-four;
    }
  }
}
