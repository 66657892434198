@import '../../../scss/layout';
@import '../../../../colors';

.authorization-background__login {
  color: $light-yellow-1;
}

.login-container {
  &--title {
    font-family: ABCSocialExtended-Medium, sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #061846;


    @media (min-width: $md-min-screen-width) {
      font-size: 40px;
      line-height: 48px;
    }
  }

  &--subtitle {
    font-style: normal!important;
    font-family: ABCSocialExtended-Regular, sans-serif!important;
    font-size: 12px!important;
    line-height: 16px!important;
    margin-top: 20px !important;
    color: #061846!important;

    @media (min-width: $md-min-screen-width) {
      font-size: 14px;
      line-height: 20px;
    }

    &__error {
      color: $red-2 !important;
    }
  }

  .authorization-form {
    .ant-form-item-explain {
      font-family: ABCSocial-Medium, sans-serif;
    }

    .ant-form-item-label {
      label {
        width: 100%;
        font-family: ABCSocial-Regular, sans-serif;

        .password-label-wrapper {
          display: flex;
          align-items: center;
          width: 100%;

          .button-container {
            width: 100%;
            text-align: right;

            .ant-btn-link {
              padding-right: 0;
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 14px;
            }
          }
        }
      }
    }
  }
}
