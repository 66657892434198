@import 'src/colors';

.secure-form {
  padding: 10px;
}

.secure-form-container {
  iframe {
    box-sizing: border-box;
    margin: 0;
    font-variant: tabular-nums;
    list-style: none;
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 10px 11px 4px 11px;
    color: rgba(0, 0, 0, 0.65);
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    height: 45px !important;
    border-radius: 6px !important;
    letter-spacing: 0 !important;
    line-height: 17px !important;
  }
}

label.required > span:last-child::before {
  display: inline-block;
  margin-right: 4px;
  content: '*';
  color: $dusty-orange;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
  text-align: right;
}

.required-field > .ant-form-item-label::after,
.ant-form-item-label > label.ant-form-item-required::after {
  display: inline-block;
  margin-left: 4px;
  content: '*';
  color: $dusty-orange;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 17px;
  text-align: right;
}

.ant-form-item-label {
  color: $dirty-purple;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 17px;
}

.colp {
  padding-right: 10px;
}

.secure-form-info {
  font-size: 12px !important;
  line-height: 18px !important;
  font-weight: normal !important;
  font-family: ABCSocial-Regular, sans-serif;

  a {
    color: #67728E !important;
    &:hover {
      color: #4D576F!important;
      text-decoration: underline !important;
    }
  }
}

.secure-form-bnt-submit {
  height: 48px !important;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;

  img {
    margin-left: 8px;
  }
}

.standalone-secure-form {
  background: #f2edf0;
  padding: 26px 12px;

  .card-data-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }

  .card-data-header-content {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: #061846;
    letter-spacing: -0.2px;
  }

  .change-card-header {
    margin: 20px 0;
    font-size: 18px;
    line-height: 16px;
    color: #564357;
  }

  .ant-form-item-label > label {
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    /* Core/Blue/900 (Text Primary, Button) */

    color: #061846;
  }

  .checkbox-inner-content {
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
  }

  .card-data-container {
    padding: 20px;
    border-radius: 20px;
    background: white;

    .secure-form-bnt-submit {
      background: #061846;
      border-color: #061846;
      border-radius: 8px;

      span {
        font-weight: normal;
      }
    }
  }

  .agreements-container {
    padding: 20px;

    p {
      margin-bottom: 10px;
    }
  }
}

.ant-checkbox-inner {
  box-sizing: border-box;
  height: 24px;
  width: 24px;
  border-radius: 6px;
}
