@import '../../../scss/layout';
@import '../../../../colors';

.validation-rules {
  font-family: ABCSocial-Regular, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: $dark-gray;

  &-title {
    margin-top: 10px;
    opacity: 0.5;
  }

  .validation-rules-wrapper {
  }

  &-item {
    color: $dark-gray;
    padding: 5px 0;
    display: flex;
    opacity: 0.5;
    align-items: center;
  }

  .validation {
    &--error {
    }

    &--success {
      color: $dark-green !important;
      opacity: 1;
    }

    &-mark {
      height: 10px;
      width: 13px;
      margin-right: 10px;
      background: url('../../../assets/img/icon-dash.svg') center center
        no-repeat;
      display: inline-block;

      &-wrapper {
      }

      &--success {
        width: 13px;
        height: 11px;
        background: url('../../../assets/img/icon-tick-green.svg') center center
          no-repeat;
      }
    }
  }
}
