@import '../../../../colors';

.composition-view-style {
  .info-box {
    background-color: $light-yellow;
  }

  .prescription-preferences-box {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .info-box-with-list {
    height: 100%;
  }

  .info-box-horizontal {
    .ant-col {
      display: flex;
    }

    h1,
    h4,
    .ant-btn {
      align-self: center;
    }

    .right-button {
      height: 48px;
      width: 211px;
      border-radius: 27.5px;
    }

    @media (max-width: 768px) {
      .right-button {
        margin-top: 24px;
      }
    }
  }

  @media (max-width: 992px) {
    .prescription-preferences-box {
      margin-top: 20px;
      max-width: 100%;
      width: 100%;
    }
  }

  .margin-top-mobile {
    margin-top: 20px;
  }
}
