@import '../../../colors';

// react-calendar customisation
.react-calendar {
  width: 100%;
  padding: 5px 20px;
  background-color: transparent;
  border: none;

  // Header

  .react-calendar__navigation {
    align-items: center;
  }

  .react-calendar__navigation__label {
    &,
    &[disabled] {
      font-size: 20px;
      font-weight: 600;
      text-transform: capitalize;
      text-align: left;
      background-color: transparent;
      color: $dirty-purple;
    }
  }

  .react-calendar__month-view__days {
    align-items: center;
  }

  .react-calendar__navigation__prev-button,
  .react-calendar__navigation__next-button {
    order: 2;
    width: 50px;
    height: 24px;
    border-radius: 4px;
    border: solid 1px #c9c2c8;
    background-color: #ffffff;
    margin-left: 6px;
    color: $dirty-purple;
    font-size: 10px;
  }

  .react-calendar__month-view__weekdays__weekday {
    //width: 67px;
    //height: 19px;
    font-size: 16px;
    font-weight: bold;

    abbr {
      text-align: center;
      text-decoration: none;
      color: $dirty-purple;
    }
  }

  // Tiles

  .react-calendar__month-view__days__day--weekend {
    color: unset;
  }

  .react-calendar__tile {
    &,
    &:disabled {
      font-size: 16px;
      height: 32px;
      width: 32px;
      font-weight: bold;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 14px 0;
      background-color: transparent;
      color: $dirty-purple;
    }

    &.is-after-today {
      background: #ffe7cf;
      color: #5a4058;
    }
  }

  .react-calendar__tile--now {
    //height: 60px;
    //width: 60px;
    margin: 0;
    text-align: center;
    color: $dusty-orange;
    position: relative;
    overflow: visible !important;

    &,
    &:hover,
    &:focus {
      background-color: transparent;
    }

    &:before {
      content: '';
      width: 60px;
      height: 60px;
      border-radius: 30px;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.07);
      background-color: #ffffff;
      position: absolute;
      z-index: 1;
    }

    abbr {
      color: $dusty-orange;
      position: relative;
      z-index: 2;
    }

    &:not(.is-last-date):after {
      content: '';
      position: absolute;
      right: 0;
      left: 50%;
      top: 0;
      bottom: 0;
      z-index: 0;
    }
  }

  .is-active {
    &,
    &:disabled {
      background: $dusty-orange;
      color: #ffffff;

      &:hover,
      &:focus {
        background: lighten($dusty-orange, 10%);
        color: #ffffff;
      }

      &.is-after-today {
        background: #ffe7cf;
        color: #5a4058;
      }
    }
  }

  .react-calendar__tile--active {
    &.is-active {
      background: $dusty-orange;
      color: #ffffff;

      &:enabled:not(.is-disabled) {
        &:hover,
        &:focus {
          background: lighten($dusty-orange, 10%);
        }
      }
    }

    &.is-after-today {
      background: #ffe7cf;
      color: #5a4058;
    }

    &:enabled:not(.is-active) {
      &,
      &:hover {
        background: #e6e6e6;
      }
    }
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: #cbc2c8;

    &.is-active:not(.is-disabled),
    &.react-calendar__tile--hasActive {
      color: #ffffff;
    }
  }

  .react-calendar__tile--rangeStart:not(.react-calendar__tile--rangeBothEnds) {
    border-top-left-radius: 1000px;
    border-bottom-left-radius: 1000px;
  }

  .react-calendar__tile--rangeEnd:not(.react-calendar__tile--rangeBothEnds) {
    border-top-right-radius: 1000px;
    border-bottom-right-radius: 1000px;
  }

  .react-calendar__tile {
    &:nth-child(7n-6):not(.is-disabled) {
      border-top-left-radius: 1000px;
      border-bottom-left-radius: 1000px;
    }

    &:nth-child(7n):not(.is-disabled) {
      border-top-right-radius: 1000px;
      border-bottom-right-radius: 1000px;
    }
  }

  .is-active {
    &.is-disabled {
      &,
      &:hover {
        background-color: transparent;
        color: #c9c2c8;
      }
    }
  }
}

// Legend
.calendar-legend {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 30px;
  font-family: ABCSocial-Regular, sans-serif;

  .legend-item {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    color: $dirty-purple;
    margin: 5px 0;
  }

  .legend-icon {
    width: 12px;
    height: 12px;
    border-radius: 10px;
    border: 1px solid;

    &.filled {
      border-color: $dusty-orange;
      background-color: $dusty-orange;
    }

    &.disabled {
      display: flex;
      justify-content: center;
      align-items: center;
      border-color: #c9c2c8;

      &:after {
        content: '';
        background-color: #c9c2c8;
        width: 4px;
        height: 4px;
        border-radius: 2px;
      }
    }

    &.empty {
      border-color: $dusty-orange;
    }
  }

  .legend-label {
    padding-left: 10px;
  }
}

.calendar-header {
}

.progressbar-wrapper {
  display: flex;
  align-items: center;
}

.progressbar-container {
  width: 100%;
  height: 6px;
  border-radius: 3px;

  &.progressbar-full-container {
    background-color: #e4f7d2;
  }

  &.progress-half-empty-container {
    background-color: #ffe9d5;
  }

  &.progress-nearly-empty-container {
    background-color: #ffdad5;
  }

  .progressbar-filled {
    width: 0;
    height: 6px;
    border-radius: 3.5px;

    &.progressbar-full-filled {
      background-color: #6a9f2f;
    }

    &.progress-half-empty-filled {
      background-color: #eb8221;
    }

    &.progress-nearly-empty-filled {
      background-color: #ec2a18;
    }
  }
}

.progress-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0 20px;
  font-size: 16px;
  font-weight: bold;
  color: $dirty-purple;
}

// Confirm modal content
