@import '../../../../colors.scss';

.panel-style {
  background-color: #F2EDF0;
  p {
    color: #061846!important;
  }
}

.go-back {
  background: #F2EDF0;
  border-bottom: 1px solid #061846!important;
  padding: 30px 100px;
  width: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 650px) {
    padding: 10px 100px;
  }

  .go-back-btn {
    color: $dirty-purple;
    font-family: 'ABCSocialExtended-Medium', sans-serif;
    font-size: 16px;
    font-weight: 600;
    height: fit-content;
    padding-left: 0;
    border: transparent;

    &:hover {
      border: transparent;
    }

    svg {
      width: 15px;
    }
  }
}

.secure-form-wrapper {
  font-size: 16px;
  max-width: 600px;
  margin: auto;
  color: #061846;

  @media (max-width: 650px) {
    padding: 15px;
  }

  .ant-btn-primary {
    font-family: 'ABCSocialExtended-Regular', sans-serif;
    font-weight: 600;
    line-height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 6.4px 20px;
    font-size: 16px;
    border-radius: 40px;

    height: 55px;
    vertical-align: top;
    min-height: 40px;
    max-height: 55px;
  }

  .payu-button-container {
    padding: 10px 11px 4px 11px;
  }

  h1 {
    font-family: 'ABCSocialExtended-Medium', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 36px;
    padding: 30px 0;
  }

  .order-price {
    margin-bottom: 30px !important;
    font-family: 'ABCSocialExtended-Regular', sans-serif;
    font-weight: bold;
  }

  h2 {
    padding: 20px 0;
    font-size: 20px;
    line-height: 30px;
  }

  .payment-method__radio-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding: 0.75rem;
    cursor: pointer;

    .custom-radio-button span:last-child {
      strong {
        display: block;
      }
      span {
        white-space: normal;
      }
    }

    & > img {
      height: 2rem;
      margin-right: 0.6rem;
    }
  }
  .payment-method__paypal {
    margin-top: 0.75rem;
    & > img {
      height: 3rem;
      margin-right: 1rem;
    }
  }

  .custom-radio-button {
    display: flex;

    .ant-radio {
      padding-left: 0;
      align-self: center;
    }

    .ant-radio-checked {
      .ant-radio-inner::after {
        background: url('../../../../assets/img/check-radio-mark.svg') center
          center no-repeat;
      }
    }

    input {
      width: 24px;
      height: 24px;
    }

    .ant-radio-inner {
      width: 24px;
      height: 24px;

      &::after {
        width: 18px;
        height: 18px;
        top: 2px;
        left: 2px;
      }
    }
  }

  .paypal-buttons-container {
    div > div > iframe {
      min-height: 250px;
    }
    min-height: 290px;
    .paypal-loader {
      width: 100%;
      height: 55px;

      @media (max-width: 650px) {
        height: 45px;
      }
    }
  }
}
