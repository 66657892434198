@import '../../../../../colors';

.settings-form {
  width: 370px;

  .ant-form-item-label {
    padding-bottom: 0;
  }

  .password-input {
    border: 1px solid #d9d9d9;
  }

  label {
    color: $dirty-purple !important;
    font-size: 14px !important;
    line-height: 1.71;
    font-weight: bold;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px $snow-white inset !important;
  }

  .ant-form-item-control-input-content {
    height: 48px;
  }

  .ant-input-affix-wrapper,
  input {
    height: 100%;
    border-radius: 6px;
  }

  .ant-input-suffix {
    display: flex;
    align-items: center;
  }

  .ant-btn-link {
    background-color: transparent !important;
  }

  input {
    color: $dirty-purple;
    font-size: 16px;
    font-weight: bold;
  }

  .ant-form-item-explain {
    font-size: 13px;
    font-weight: bold;
  }

  .ant-row {
    margin-bottom: 16px;
  }

  @media (max-width: 512px) {
    width: 100%;

    input {
      font-size: 17px;
    }

    .ant-input-affix-wrapper,
    input {
      border-radius: 0;
    }

    label,
    input,
    .ant-form-explain,
    .ant-input-affix-wrapper {
      padding-left: 20px;
    }

    .ant-input-affix-wrapper {
      padding-right: 20px;
    }
  }
}

.product-settings {
  width: 370px;

  .product-settings-exclusions {
    li {
      padding: 10px 0;
      display: flex;
      align-items: center;

      .ant-avatar {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px;

        img {
          width: auto;
          height: auto;
        }
      }

      .product-exclusions-text {
        color: $dirty-purple;
        font-size: 16px;
        font-weight: bold;
      }

      .ant-switch {
        margin-left: auto;
      }
    }
  }

  .product-settings-flavour {
    .product-flavour-header {
      color: $dirty-purple;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 10px;
      margin-top: 25px;
    }

    .product-flavour-text {
      color: $dusty-pink;
      font-size: 15px;
      font-weight: 500;
    }

    .ant-radio-group {
      margin-bottom: 10px;
      width: 100%;
    }

    .ant-radio-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-right: 0;
    }

    .ant-radio {
      margin-left: 5px;
      margin-right: 5px;

      .ant-radio-inner {
        border-color: $dusty-pink !important;

        &::after {
          background-color: $dirty-purple;
        }
      }
    }

    .ant-radio-wrapper-checked {
      .product-flavour-text {
        color: $dirty-purple;
        font-weight: bold;
      }
    }
  }

  @media (max-width: 512px) {
    width: 100%;

    .product-settings-flavour .product-flavour-header,
    .product-settings-flavour .ant-radio-wrapper {
      padding-left: 20px;
      padding-right: 20px;
    }

    .product-settings-exclusions {
      border-top: 1px solid $very-light-pink-four;

      li {
        padding-left: 15px;
        padding-right: 20px;
        border-bottom: 1px solid $very-light-pink-four;
        background-color: $snow-white;

        .product-exclusions-text {
          font-size: 17px;
        }
      }
    }

    .product-settings-flavour {
      .product-flavour-header {
        font-size: 14px;
      }

      .product-flavour-text {
        font-size: 17px;
      }

      .ant-radio-group {
        border-top: 1px solid $very-light-pink-four;
      }

      .ant-radio-wrapper {
        background-color: $snow-white;
        border-bottom: 1px solid $very-light-pink-four;
        padding-top: 18px;
        padding-bottom: 18px;
        display: flex;
        flex-direction: row-reverse;

        & > span {
          width: 100%;
        }

        .ant-radio {
          width: fit-content;
          border: none;

          &::after {
            border: none;
          }

          .ant-radio-input:focus + .ant-radio-inner {
            box-shadow: none;
            border: none;
          }

          &-checked {
            .ant-radio-inner {
              background: url(../../../../assets/img/icon-tick.svg) no-repeat
                center;
            }
          }

          .ant-radio-inner {
            border: none;
            transition: none;

            &::after {
              background: none;
              border: none;
            }
          }
        }

        .product-flavour-text {
          width: 100%;
        }
      }
    }
  }
}

.calendar-settings {
  width: 300px;

  @media (max-width: 512px) {
    width: 100%;
  }

  .ant-input-affix-wrapper,
  .ant-picker {
    border-radius: 6px;
    width: 100%;
    height: 48px;
  }

  .ant-input-suffix {
    display: flex;
    align-items: center;
  }

  .switch-row {
    display: flex;
    justify-content: space-between;
    color: $dirty-purple;
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    padding: 15px 0;

    @media (max-width: 512px) {
      background-color: $snow-white;
      padding: 20px 35px;
      box-shadow: 0 -0.5px 0 0 $very-light-pink-four,
        0 0.5px 0 0 $very-light-pink-four;
      font-size: 17px;
      line-height: 21px;
    }
  }

  .calendar-settings-reminder {
    padding-bottom: 10px;

    .calendar-settings-reminder-description {
      color: $dirty-purple;
      font-size: 14px;
      line-height: 17px;

      @media (max-width: 512px) {
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 10px;
      }
    }
  }

  .calendar-settings-input-text {
    color: $dirty-purple;
    font-size: 14px;
    font-weight: bold;
    line-height: 17px;
    margin-top: 35px;
    margin-bottom: 10px;

    @media (max-width: 512px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .input-with-save,
  .time-picker-with-save .ant-time-picker-input {
    height: 48px;

    @media (max-width: 512px) {
      height: 60px;
    }
  }

  .input-with-save .ant-input,
  .time-picker-with-save .ant-time-picker-input {
    font-size: 16px;
    font-weight: bold;

    @media (max-width: 512px) {
      font-size: 17px;
      padding-left: 20px;
      border-radius: 0;
    }
  }

  .input-with-save {
    .ant-btn {
      padding-right: 0;

      @media (max-width: 512px) {
        padding-right: 10px;
      }
    }
  }
}

.password-submit-button,
.submit-button {
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: center;
    padding-top: 10px;
  }

  .ant-btn {
    font-size: 16px;
    font-weight: 600;
    min-height: 48px;
    min-width: 200px;
    border-radius: 24px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.07);
  }

  .ant-btn-primary:disabled {
    background-color: $very-light-pink-four;
    color: $snow-white;
  }
}
