$container-width: 1440px;
$content-max-width: 1168px;

$sm-min-screen-width: 576px;
$md-min-screen-width: 768px;
$lg-min-screen-width: 992px;
$xl-min-screen-width: 1200px;
$xxl-min-screen-width: 1600px;
$xs-max-screen-width: ($sm-min-screen-width - 1);
$sm-max-screen-width: ($md-min-screen-width - 1);
$md-max-screen-width: ($lg-min-screen-width - 1);
$lg-max-screen-width: ($xl-min-screen-width - 1);
$xl-max-screen-width: ($xxl-min-screen-width - 1);
