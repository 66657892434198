.new-offer-header {
  background-color: transparent !important;
}

.header {
  padding: 48px 15px;
  display: flex;
  justify-content: space-between;
  z-index: 900;

  @media (max-width: 32em) {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    padding: 8px 0;
  }

  .nav {
    display: flex;
    align-items: center;

    @media (max-width: 32em) {
      width: 100%;
      text-align: center;
    }
  }

  .branding {
    position: relative;
    display: flex;

    .header-certificates {
      display: flex;
      align-items: center;
      padding-left: 25px;
      padding-right: 15px;
    }

    @media (max-width: 768px) {
      flex-direction: column;

      .header-certificates {
        padding-top: 10px;
      }
    }

    @media (max-width: 32em) {
      display: none;
    }

    .logo {
      display: block;
      width: 142px;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    @media (max-width: 512px) {
      display: flex;
      justify-content: space-evenly;
      width: 100%;
    }

    .menu-item {
      display: inline-block;
      font-size: 0;
      margin-right: 30px;

      @media (max-width: 32em) {
        margin: 0 12px;
      }

      @media (max-width: 320px) {
        margin: 0 8px;
      }

      &:last-child {
        margin-right: 0;

        @media (max-width: 32em) {
          display: none;
        }
      }

      a {
        position: relative;

        &.active {;
          .menu-item-name {
            position: relative;
            color: #4D576F!important;

            &:after {
              content: '';
              position: absolute;
              left: 0;
              bottom: -6px;
              width: 100%;
              height: 1.5px;

              @media (max-width: 32em) {
                display: none;
              }
            }
          }

          @media (max-width: 32em) {
            .icon {
              background-color: $dusty-orange;
            }

            .menu-item-name {
              color: $dusty-orange;
            }
          }
        }
      }

      a,
      button {
        cursor: pointer;
        font-size: 18px;
        line-height: 1.67;
        color: $dirty-purple;
        text-decoration: none;
        background: transparent !important;

        .menu-item-name {
          position: relative;
          font-family: 'ABCSocialExtended-Medium', sans-serif;
          font-size: 18px;
          line-height: 28px;
          font-weight: 400;
          letter-spacing: 0.02em;
          color: #67728E;

          &:hover {
            color: #4D576F;
            text-decoration: underline;
          }

          @media (max-width: 32em) {
            font-size: 11px;
            height: 20px;
          }
        }
      }

      button {
        border: 0;
        outline: 0;
        padding: 0;
        margin: 0;
      }

      .icon {
        vertical-align: middle;
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 8px;
        position: relative;
        top: -2px;
        background-size: 100% !important;
        background-color: $dirty-purple;

        @media (max-width: 32em) {
          display: block;
          margin: auto;
          height: 20px !important;
          width: auto !important;
          top: 0 !important;
        }

        &.icon-dashboard {
          width: 15px;
          height: 15px;
          -webkit-mask: url('../assets/img/icon-menu-dashboard.svg') no-repeat
            50% 50%;
          mask: url('../assets/img/icon-menu-dashboard.svg') no-repeat 50% 50%;
        }

        &.icon-composition {
          -webkit-mask: url('../assets/img/icon-menu-composition.svg') no-repeat
            50% 50%;
          mask: url('../assets/img/icon-menu-composition.svg') no-repeat 50% 50%;
        }

        &.icon-affilate {
          width: 20px;
          height: 22px;
          -webkit-mask: url('../assets/img/icon-menu-affilate.svg') no-repeat
            50% 50%;
          mask: url('../assets/img/icon-menu-affilate.svg') no-repeat 50% 50%;
        }

        &.icon-account {
          width: 22px;
          height: 19px;
          -webkit-mask: url('../assets/img/icon-menu-account.svg') no-repeat 50%
            50%;
          mask: url('../assets/img/icon-menu-account.svg') no-repeat 50% 50%;
        }

        &.icon-logout {
          width: 19px;
          height: 16px;
          -webkit-mask: url('../assets/img/icon-menu-logout.svg') no-repeat 50%
            50%;
          mask: url('../assets/img/icon-menu-logout.svg') no-repeat 50% 50%;
        }
      }
    }
  }
}
