@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
@import '../colors';

//#061846

h1, h2, h3, h4, h5, h6 {
  color: #061846!important;
}

body {
  --antd-wave-shadow-color: #061846!important;
}

.ant-input:focus, .ant-input-focused, .ant-input:hover {
    border-color: #061846!important;
}

::selection {
  background: #E6EFFF !important;
  color: #061846 !important;
}

.ant-spin-dot-item {
  background-color: #061846 !important;
}

.prescription-more-menu-buttons button:hover, .prescription-more-menu-buttons button:active, .prescription-more-menu-buttons button:focus {
  background: transparent!important;
  border: transparent!important;
}

.ant-spin-text {
  color: #061846!important;
}

.ant-checkbox-wrapper {
  color: #061846!important;
}

.reorder-btn {
  font-weight: normal!important;
}

.ant-table {
  table {
    border: 1px solid #061846!important;
  }
  thead, tbody, tr {
    border: 1px solid #061846!important;
  }
  thead tr th {
    font-weight: bold!important;
  }
  th {
    background-color: #fff!important;
    font-weight: bold;
  }
  tr {
    background-color: #fff!important;
    border: 1px solid #061846!important;
    &:hover {
      background-color: #E5EFFF!important;
    }
  }
  td {
    border-top: 1px solid #061846!important;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: #E5EFFF!important;
  }
}

.ant-collapse-extra .more-button.ant-popover-open, .ant-collapse-extra .more-button:hover, .ant-collapse-extra .more-button:focus {
  border: transparent!important;
  background-color: transparent!important;
}

.grey-button {
  background-color: transparent!important;
  border: none!important;
  color: #67728E!important;

  &:hover,
  &:active,
  &:focus {
    background-color: transparent!important;
  }

  span {
    color: #67728E!important;
    &:hover {
      color: #4D576F!important;
      text-decoration: underline!important;
    }
    &:after {
      content: ' →';
    }
  }
}

.ant-btn-primary {
  background-color: #061846 !important;
  color: #fff !important;
  font-size: 14px !important;
  height: 45px !important;
  border-color: transparent !important;
  line-height: 20px !important;
  padding: 10px !important;
  border-radius: 16px !important;
  font-family: ABCSocial-Regular, sans-serif !important;
}

.ant-btn-secondary {
  background: transparent!important;
  cursor: pointer!important;
  color: #67728E!important;
  font-weight: normal!important;
  border: transparent!important;
  &:hover {
    color: #4D576F;
    text-decoration: underline;
    border: transparent!important;
  }
}


$snow-white: #ffffff;
$dusty-orange: #061846;
$dirty-purple: #564257;
$very-light-pink: #faf9f9;
$dark-cream: #f9ef95;
$very-light-pink-two: #fcfaf7;

.vertical-center-children {
  display: flex;
  align-items: center;
}

.panel-style {
  min-height: 100vh !important;

  ::-webkit-scrollbar {
    width: 6px;
  }

  input,
  textarea {
    /* Remove First */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-family: ABCSocial-Regular, sans-serif!important;
  }

  ::-webkit-scrollbar-track {
    background: #f9f9f9;
  }

  ::-webkit-scrollbar-thumb {
    background: #564257;
  }

  * {
    box-sizing: border-box;
  }

  @import 'scss/fonts/sundose-icons';

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    color: #061846;
    font-weight: 600;
  }

  p {
    margin: 0;
    font-weight: 500;
    color: #061846;
    font-size: 19px;
    line-height: 29px;
  }

  .button {
    display: inline-block;
    width: 200px;
    height: 48px;
    border-radius: 27.5px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.07);
    background-color: $dusty-orange;
    line-height: 43px;
    text-decoration: none;
    color: #fff;
    font-weight: 600;
    text-align: center;
    font-size: 16px;
    outline: 0;
    padding: 0;
    cursor: pointer;
    border: 1px solid transparent;

    &.button-small {
      height: 36px;
      line-height: 31px;
    }
  }

  button:disabled {
    background-color: #cccccc;
  }

  .wrapper {
    max-width: 1170px;
    margin: auto;
  }

  .row:before,
  .row:after {
    content: '';
    display: table;
    clear: both;
  }

  .col-wide {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }

  @media (max-width: 32em) {
    .user-panel {
      padding: 24px 0 68px;
    }
  }

  @import 'scss/common';
  @import './scss/header';

  @import './scss/auth';
  @import './scss/auth-form';

  @import './scss/modal';

  .loader {
    font-size: 30px;
    color: $dirty-purple;
    font-weight: 500;
  }

  .loaded {
    opacity: 1;
  }
}

@import './scss/fonts/sundose-icons';

.ant-primary-button {
  width: 100%;
  margin: 0 auto;
  max-width: 270px;
  display: block;
  font-size: 16px;
  padding: 10px 30px 12px;
  height: auto;
  font-weight: 600;
  border-width: 2px;
  box-shadow: none;
  text-shadow: none;

  &:disabled {
    background-color: $very-light-pink-four;
    color: white;
    border-color: $very-light-pink-four;

    &:hover,
    &:focus,
    &:active {
      color: white;
      background-color: $very-light-pink-four;
      border-color: $very-light-pink-four;
      opacity: 0.8;
    }
  }

  a {
    font-weight: inherit;
  }
}

.ant-btn:hover, .ant-btn:focus {
  color: $dusty-orange;
  border-color: $dusty-orange;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  background: $dusty-orange !important;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: $dusty-orange !important;
}

.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #E6EFFF !important;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: $dusty-orange !important;
}

.ant-form-item-label {
  label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    font-family: ABCSocialExtended-Regular, sans-serif;
    color: #061846;
  }
}

.ant-checkbox-wrapper {
  font-family: ABCSocial-Regular, sans-serif;
}

.ant-form-item-explain {
  font-family: ABCSocial-Medium, sans-serif;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #061846 !important;
  border-color: #061846 !important;
}

.ant-btn:not(.ant-btn-link) {
  box-shadow: none;
  text-shadow: none;

  span,
  i {
    position: relative;
    //top: -2px;
  }

  img {
    top: -1px;
    position: relative;
  }
}

.ant-primary-button-ghost {
  border-width: 1px;
}

.custom-tag-style {
  color: $dirty-purple;
  border-radius: 2px;
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
  min-height: 22px;
  display: inline-flex;
  align-items: center;
  margin: 0;
}

.ant-notification {
  @media (max-width: 512px) {
    width: 100% !important;
    padding-left: 18px !important;
  }
}

.notification-style {
  .ant-notification-notice-message {
    font-size: 14px;
    font-weight: 500;
  }

  @media (max-width: 512px) {
    text-align: center;
  }
}

.notification-style-success {
  @extend .notification-style;

  .ant-notification-notice-message,
  svg {
    color: $notification-success;
  }
}

.notification-style-error {
  @extend .notification-style;

  .ant-notification-notice-message,
  svg {
    color: $red;
  }
}

.icon {
  transition: transform ease-in-out 0.3s;
  position: relative;

  &.rotated {
    svg {
      transform: rotate(180deg);
      transition: transform ease-in-out 0.3s;
    }
  }

  &.not-rotated {
    svg {
      transform: rotate(0deg);
      transition: transform ease-in-out 0.3s;
    }
  }
}
