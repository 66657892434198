.personalise-banner-container {
  display: flex;
  width: 100%;
  min-height: 60px;
  padding: 16px 24px;
  margin: 20px 0;
  background: #FFEBA9;
  border-radius: 12px;
  color: #061846;
  align-items: center;
  gap: 10px;
  justify-content: flex-start;
  font-family: ABCSocialExtended-Regular, sans-serif;
}
.personalise-banner-message {
  color: #061846;
  font-size: 16px;
  line-height: 24px;
  max-width: 700px;

  @media (max-width: 900px) {
    max-width: 499px;
  }
}
.dietitian-add-button {
  clear: both;
  padding: 20px 40px 0 0;
  display: inline-block;
  cursor: pointer;
  color: #67728E;
  &:hover {
    color: #4D576F;
    text-decoration: underline;
  }
  &:after {
    content: ' →';
  }
}

.personalise-banner-buttons {
  margin-left: auto;
}

.personalise-banner-button {
  border-color: #e7792b;
  font-size: 14px;
  line-height: 20px;
}

.personalise-banner-primary-button {
  margin-right: 8px;
  span {
    font-weight: 400;
    color: white;
  }
}

.personalise-banner-secondary-button {

}
