@import '../../../colors';

.action-modal {
  box-shadow: 0 0 30px 0 rgba(0, 1, 0, 0.05), 0 1px 5px 0 rgba(0, 0, 0, 0.15);
  padding-bottom: 0;
  position: static;

  .ant-modal-content {
    position: inherit;
    padding: {
      top: 5px;
      bottom: 30px;
    }
  }

  .ant-modal-body {
    padding: {
      top: 0;
      bottom: 0;
    }

    .ant-legacy-form-item,
    .ant-form-item {
      margin-bottom: 10px;
    }

    .ant-legacy-form-item-control-wrapper {
      width: 100%;
    }

    .ant-legacy-form-item-control input,
    .ant-form-item-control input {
      min-height: 48px;
      color: $dirty-purple !important;
      font-size: 16px;
      font-weight: bold;
      line-height: 19px;
      border-radius: 6px;
    }

    .ant-legacy-form-explain {
      font-size: 12px;
      line-height: 15px;
      text-align: right;
    }

    .ant-legacy-form-item-label,
    .ant-form-item-label {
      font-size: 14px;
      line-height: 17px;
      color: $dirty-purple;
      font-weight: bold;

      label {
        color: inherit;
      }
    }
  }

  .ant-modal-header {
    border-bottom: none;

    .ant-modal-title {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      color: $dirty-purple;
    }
  }

  .ant-modal-close {
    position: absolute;
    top: 40px;
    right: 40px;
    background: white;
    border-radius: 100%;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
    width: 36px;
    height: 36px;
    color: $dirty-purple;

    .ant-modal-close-x {
      width: inherit;
      height: inherit;
      display: flex;
      align-items: center;

      i {
        margin: 0 auto;
      }
    }
  }

  .ant-modal-footer {
    border-top: none;

    .action-modal-footer {
      display: flex;
      width: fit-content;
      margin: 0 auto;
      padding: {
        right: 10px;
        left: 10px;
      }

      @media (max-width: 512px) {
        flex-direction: column;
      }

      button {
        min-width: 150px;
        font-weight: 600;
        height: 48px;
        font-size: 16px;
        border-width: 2px;

        &:first-child {
          margin-right: 10px;
        }

        &:last-child {
          margin-left: 10px;
        }

        span {
          position: relative;
          top: -2px;
        }

        @media (max-width: 512px) {
          height: 36px;
          min-width: 114px;
        }
      }
    }
  }
}

.action-modal-wrap {
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(24px);
  -webkit-backdrop-filter: blur(24px);

  .action-modal {
    top: 0;
    max-width: 83% !important;
  }
}
