@import '../../../../../../colors';
@import '../../../../../scss/layout';

.quick-register-form {
  .checkbox-item {
    .ant-checkbox-wrapper {
      span {
        font-size: 12px;
        line-height: 16px;
        font-family: ABCSocial-Regular, sans-serif;
        font-weight: 400;
        text-align: justify;
        color: #061846!important;
      }
    }
  }

  .ant-alert-message > a {
    color: #3f46c8;
    font-weight: 600;
    font-family: ABCSocialExtended-Regular, sans-serif;
  }

  .ant-form-item-explain {
    font-size: 12px;
    font-family: ABCSocialExtended-Regular, sans-serif!important;
    margin: 1px 0 0;
    font-weight: 500;
  }

  .submit-button {
    margin: 6px 0 25px;

    .ant-btn {
      background-color: $accent-color;
      border-color: $accent-color;
      font-style: normal;
      font-weight: 400;
      font-family: ABCSocial-Regular, sans-serif;
      font-size: 14px;
      line-height: 20px;
      padding: 10px 20px;
      letter-spacing: 0.02rem;
      height: 44px;
      min-width: 170px;
      border-radius: 12px;

      &:disabled {
        background-color: $light-gray-3;
        border-color: $light-gray-3;
        color: white;
      }

      &:hover {
        opacity: 0.8;
      }

      @media (max-width: $md-max-screen-width) {
        width: 100%;
      }
    }
  }

  &__email-field {
    position: relative;
    margin-bottom: 0;

    .ant-form-item-label {
      label {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        font-family: ABCSocialExtended-Regular, sans-serif;
        color: #061846;
      }
    }

    .ant-form-item-control-input-content {
      .ant-input {
        //max-width: 410px;
        border-color: transparent;
        min-height: 44px;
        letter-spacing: 0.3px;
        font-size: 16px;
        border-radius: 8px;
        @media (max-width: $md-max-screen-width) {
          width: 100%;
          max-width: none;
        }
      }

      .img {
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(36px, -16px);
        @media (max-width: $md-max-screen-width) {
          display: none;
        }

        svg {
          width: 150px;
          height: 155px;
        }
      }
    }
  }

  .auth-footer {
    margin: 60px 0 0;
    padding: 20px 0 80px 0;
    border-top: 1px solid #E8E8E8;
    display: flex;
    align-items: center;

    &--title {
      font-size: 16px;
      line-height: 24px;
      font-family: 'ABCSocialExtended-Medium', sans-serif;
    }

    &--actions {
      .ant-btn {
        padding: 0;
        height: auto;
        margin: 0 0 0 12px;
        color: #061846;

        a {
          font-weight: 500;
          font-size: 18px;
          line-height: 28px;
          font-family: ABCSocial-Regular, sans-serif;
          color: #67728E;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &__extra-agreements {
    font-family: ABCSocial-Regular, sans-serif;
    color: #061846!important;
    font-size: 12px;
    line-height: 16px;
    &,
    a {
      font-size: 12px !important;
      line-height: 18px !important;
    }

    a {
      display: inline;
      padding: 0;

      span {
        color: #67728E;
        font-weight: 400;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
