@import '../../../../colors';

$calendar-cell-radius: 20px;

.month-calendar {
  margin: 0 auto;
  padding: 40px;
  border-radius: 16px;
  background-color: white;

  .calendar-header {
    display: flex;
    padding: 10px;

    .calendar-date {
      width: 100%;
      font-size: 18px;
      line-height: 28px;
      font-family: ABCSocialExtended-Medium, sans-serif;
      color: #061846;
    }

    .calendar-navigation {
      min-width: 63px;
    }
  }

  thead {
    th {
      font-family: ABCSocialExtended-Medium, sans-serif;
      color: #061846;
    }
  }

  .ant-picker-calendar {
    background-color: transparent;
  }

  .custom-cell-container {
    height: 100%;
    display: flex;
    align-items: center;

    .custom-cell-calendar {
      width: 100%;
      height: 35px;
    }
  }

  .ant-picker-panel {
    border-top: none;
    background-color: transparent;

    .ant-picker-body {
      padding: 8px 12px;
    }
  }

  tbody {
    font-weight: bold;

    tr {
      td:last-child:hover {
        .custom-cell-calendar {
          border-top-right-radius: $calendar-cell-radius;
          border-bottom-right-radius: $calendar-cell-radius;
        }
      }

      td:first-child {
        .custom-cell-calendar {
          border-bottom-left-radius: $calendar-cell-radius;
          border-top-left-radius: $calendar-cell-radius;
        }
      }
    }

    .ant-picker-cell:hover {
      .custom-cell-calendar.current {
        background-color: transparent;
      }

      .custom-cell-calendar {
        background-color: #e6e6e6;
      }
    }

    .ant-picker-cell {
      height: 60px;
      width: 60px;
      cursor: pointer;
      padding: 0;
      font-family: ABCSocial, sans-serif;

      &::before {
        display: none;
      }

      .custom-cell-calendar {
        padding: 5px;
        color: #aeaeae;
      }

      &,
      &-disabled {
        font-size: 16px;
        background-color: transparent;
        color: #97A1BA;
      }

      .is-after-today {
        background: #97A1BA;
      }

      &:nth-child(7n-6):not(.is-disabled) {
        .custom-cell-calendar {
          border-top-left-radius: 1000px;
          border-bottom-left-radius: 1000px;
        }
      }

      &:nth-child(7n):not(.is-disabled) {
        .custom-cell-calendar {
          border-top-right-radius: 1000px;
          border-bottom-right-radius: 1000px;
        }
      }

      .is-start-date,
      .is-first-date {
        border-top-left-radius: 1000px;
        border-bottom-left-radius: 1000px;
      }

      .is-end-date,
      .is-last-date {
        border-top-right-radius: 1000px;
        border-bottom-right-radius: 1000px;
      }

      .is-active {
        &,
        &:disabled {
          background: $dusty-orange;
          color: #ffffff!important;
        }
      }

      .current.is-today.is-active {
        &::before {
          position: absolute;
          content: '';
          width: 50%;
          height: 35px;
          left: 0;
          background-color: $dusty-orange;
          color: #fff!important;
          top: 12.5px;
        }

        &.is-first-date::before,
        &.is-start-date::before {
          border-bottom-left-radius: 1000px;
          border-top-left-radius: 1000px;
        }
      }

      .current.is-today:not(.is-last-date) {
        &:not(:nth-child(7n))::after,
        &:not(:nth-child(7n + 6))::after {
          position: absolute;
          background-color: $dusty-orange;
          content: '';
          width: 50%;
          height: 35px;
          top: 12.5px;
        }
      }

      &:hover,
      &:focus {
        .is-active {
          background: lighten($dusty-orange, 10%);
          color: #ffffff!important;
        }

        .is-after-today {
          background: #E6EFFF;
          color: $dirty-purple;
        }
      }

      .next-delivery-date {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        &:before {
          content: '';
          position: absolute;
          width: 32px;
          height: 32px;
          border: 1px solid #e87831;
          -webkit-border-radius: 16px;
          -moz-border-radius: 16px;
          border-radius: 16px;
        }
      }

      .custom-cell-calendar.current {
        padding: 0;
        background-color: transparent;

        .cell-wrapper {
          width: 60px;
          height: 60px;
          border-radius: 30px;
          box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.07);
          color: $dusty-orange;
          background-color: #E6EFFF;
          font-family: ABCSocial, sans-serif;
          margin: 0 auto;
          display: flex;
          align-items: center;
          position: relative;
          z-index: 2;
          top: -12.5px;

          @media (max-width: 32em) {
            width: 45px;
            height: 45px;
            top: -5px;
          }
        }

        .text {
          margin: 0 auto;
        }
      }
    }

    .ant-picker-cell-in-view {
      .custom-cell-calendar {
        color: #061846;
      }
    }

    .ant-picker-cell-disabled {
      cursor: not-allowed;

      .cell-wrapper {
        cursor: not-allowed;
      }
    }
  }
}

// Confirm Modal

.confirm-date-taken {
  background-color: #ffffff;
  border-radius: 12px;
  border: 1px solid $dusty-orange;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 48px;
  margin: 30px;
  line-height: 1.4;
  text-align: center;
  color: $dirty-purple;

  h3 {
    color: $dirty-purple;
    font-family: 'ABCSocialExtended-Medium', sans-serif;
    font-size: 40px;
    line-height: 48px;
  }

  .selected-date {
    font-size: 24px;
    line-height: 32px;
    font-family: 'ABCSocialExtended-Medium', sans-serif;
    text-align: center;
    margin-top: 10px;
  }

  .selected-day-name {
    font-size: 18px;
    font-weight: normal;
  }

  .confirm-label {
    margin: 24px 0;
  }

  .image {
    width: 186px;
    margin-top: 50px;
  }
  .ant-btn:hover, .ant-btn:focus {
    color: #fff;
    border-color: transparent;
  }
}
