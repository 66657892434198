.password-input {
  height: 44px;
  border-radius: 8px;
  background: white;
  border-color: white;
  padding: {
    top: 0;
    right: 14px;
    left: 0;
    bottom: 0;
  }

  &:hover,
  &:active,
  &:focus {
    background: white;
  }

  input {
    padding: {
      left: 0;
      right: 0;
    }
    height: initial !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    z-index: 1;
    border: none !important;
  }

  .ant-input-password-icon {
    display: flex;
    align-items: center;
  }
}
