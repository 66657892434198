.typ-wrapper {
  background: #F2EDF0;
  width: 100%;
  @media (max-width: 768px) {
    padding: 0 30px;
  }
}

.typ-container {
  margin: 0 auto;
  height: 100%;
  max-width: 620px;

  display: flex;
  flex-direction: column;
  align-items: center;

  h1,
  h2,
  h3,
  span {
    font-family: 'ABCSocialExtended-Medium', sans-serif;
    color: #061846 !important;
    font-weight: 400 !important;
  }

  h1 {
    font-size: 46px;
    line-height: 132%;
    max-width: 460px;
    text-align: center;
    @media (max-width: 768px) {
      max-width: 100%;
      font-size: 24px;
      line-height: 38px;
    }
  }

  h2 {
    font-size: 14px;
    line-height: 22px;
    font-family: ABCSocialExtended-Regular, sans-serif;
    margin: 0 60px!important;
    text-align: center;
  }

  h3 {
    font-size: 19px;
    line-height: 132%;
  }

  span {
    font-size: 15px;
    line-height: 16px;
    font-family: ABCSocialExtended-Regular, sans-serif;
    color: #061846;
  }

  img {
    margin: 40px 0 70px 0;

  }

  .card {
    width: 100%;
    background: white;
    padding: 40px;
    border-radius: 32px;
    margin: 40px 0;
  }

  .big-card {
    padding: 40px;
  }

  .order-status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 768px) {
      justify-content: center;
    }

    span {
      text-align: left;
      display: block;
      margin: 10px 0;
    }

    .badge {
      padding: 10px 16px;
      background: #ecb33c;
      border-radius: 12px;
      font-family: ABCSocial, sans-serif;
      font-size: 14px;
      line-height: 20px;
      color: white !important;

    }

    img {
      margin: 0;
      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  .customer-data {
    padding: 24px 0;
    display: flex;
    flex-direction: column;


    .addresses {
      display: flex;
      margin-top: 12px;
      flex-direction: row;
      @media (max-width: 768px) {
        flex-direction: column;
      }

      .address {
        min-width: 300px;

        span {
          display: block;
          margin: 8px 0;

          &:first-of-type {
            margin-bottom: 24px;
          }
        }
      }
    }
  }

  .order-details {
    padding: 24px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .order-details-right > img {
      width: 47px;
      height: 30px;
      margin: 0;
    }
  }

  .details-upper-border {
    border-top: 1px solid #e8e8e8;
  }
}

.typ-footer {
  position: sticky;
  bottom: 0;
  height: 70px;
  background: transparent;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

}

.go-to-dashboard-btn,
.go-to-dashboard-btn:hover {
  background: #061846;
  border-radius: 12px;
  height: 46px;
  width: 171px;
  padding: 10px 16px;

  span {
    font-family: ABCSocial, sans-serif;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.02rem;
    color: white !important;
  }
}

.typ-subscription-container {
  padding: 50px 0;

  .go-to-dashboard-btn {
    margin-top: 50px;
  }
}
