@import '../../../colors';

.flex-dir-column > .ant-col {
  @media (max-width: 991px) {
    margin: 15px 0;
  }
}

.info-box {
  background-color: white !important;
  border-radius: 16px;
  color: $dirty-purple !important;
  height: 100%;
  width: 100%;
  text-align: left;
  font-size: 15px;
  padding: 40px;

  .content-wrapper {
    height: 100%;
  }

  .ant-spin-nested-loading,
  .ant-spin-container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .ant-btn {
    font-family: 'ABCSocialExtended-Medium', sans-serif;
    height: 36px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 20px;
  }

  h1,
  .info-box-title {
    color: $dirty-purple !important;
    font-family: 'ABCSocialExtended-Medium', sans-serif;
    font-size: 18px;
    line-height: 28px;
    font-weight: 600 !important;
    margin-bottom: 24px !important;
  }

  h3,
  h4,
  h5,
  div {
    color: $dirty-purple !important;
    font-family: ABCSocial-Regular, sans-serif;
    font-weight: normal !important;
  }

  h3 {
    font-size: 16px;
  }

  h4,
  div {
    font-size: 15px;
  }

  h5 {
    font-size: 13px;
  }

  .right-button {
    margin-top: 12px;
  }
}

.info-box-with-list {
  display: flex;

  .ant-list {
    .ant-avatar {
      background-color: $snow-white;
      display: flex;
      justify-content: center;

      img {
        align-self: center;
        height: 50%;
        width: 50%;
        object-fit: initial;
      }
    }

    h1,
    .info-box-title {
      margin-bottom: 24px;
    }
  }
}

.info-box-horizontal {
  .info-box {
    flex-direction: row;
  }

  .right-button {
    margin-top: 0;
    margin-left: 24px;
  }

  @media (min-width: 768px) {
    h1,
    .info-box-title {
      margin-bottom: 0 !important;
    }
  }

  @media (max-width: 768px) {
    .info-box {
      flex-direction: column;
    }

    .right-button {
      margin-top: 12px;
    }
  }
}

.info-box-mixer {
  .ant-list {
    width: 100%;
  }

  .ant-list-items,
  .ant-list-item,
  .ant-list-item-meta,
  .ant-list-item-meta-content,
  .ant-list-item-meta-description {
    height: 100%;
  }

  .ant-list-item {
    padding: 0!important;
    margin: 0!important;
  }

  .ant-list-item-meta-content,
  .ant-list-item-meta-description {
    display: flex;
    flex-direction: column;
    font-family: ABCSocial-Regular, sans-serif;
    font-size: 14px;
    line-height: 20px;
  }

  .ant-list-item-meta-description {
    justify-content: space-between;
  }

  .buttons-row {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;

    .ant-btn {
      width: 45%;
      background-color: $dusty-orange!important;
      color: #fff!important;
      font-size: 14px!important;
      height: 45px!important;
      border-color: transparent!important;
      line-height: 20px!important;
      padding: 10px!important;
      border-radius: 16px!important;
      font-family: ABCSocial-Regular, sans-serif!important;
    }

    a {
      font-family: ABCSocial-Regular, sans-serif!important;
      font-size: 14px;
      font-weight: 500;
    }

    @media (max-width: 512px) {
      flex-direction: column;

      .ant-btn {
        width: 100%;
        margin-top: 15px;
      }
    }
  }
}
