@import '../../../colors';

.warranty-style {
  display: flex;
  flex-direction: column;
  align-items: center;

  .warranty-style-logo {
    margin-bottom: 10px;
  }

  .warranty-style-title {
    color: $dirty-purple;
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    line-height: 19px;
    margin-bottom: 10px;
  }

  .warranty-style-description {
    color: $dirty-purple;
    font-family: Lato;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
  }
}

.reimbursement-warranty-style .warranty-style-title {
  color: $light-green;
}

.certificates-icons {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 10px;

  img {
    max-width: 100%;
    padding: 0 5px;
  }
}
