@import '../../../../colors';

.custom-picker {
  height: fit-content;
  align-self: center;

  .custom-picker-title {
    color: $dirty-purple;
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    display: flex;
    align-items: center;
    line-height: 17px;

    .anticon-down,
    .anticon-up {
      margin-left: 5px;

      svg {
        height: 10px;
      }
    }
  }
}

.custom-picker-option {
  padding: 20px 20px 0;
  color: $dirty-purple;
  font-family: Lato;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  display: flex;
  align-items: center;
  white-space: nowrap;

  &:last-child {
    padding-bottom: 20px;
  }

  img {
    margin-right: 15px;
  }

  &:hover {
    cursor: pointer;
  }
}

.ant-popover-placement-bottom {
  z-index: 2000;

  @media (min-width: 768px) {
    padding-top: 0;
  }

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner-content {
    padding: 0;
  }
}
