@import '../../../../../colors';

.settings-wrapper {
  width: 100%;
}

.settings-main-navigation {
  width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // height: 100%;
  background: white;
  padding: 40px;
  border-radius: 16px;

  .sign-out-btn {
    height: 48px;
    width: 210px;
    border-radius: 24px;
    font-family: 'ABCSocialExtended-Medium', sans-serif;
    font-size: 16px;
    font-weight: 600;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.07);
    // margin-top: auto;
    margin: 40px 0;
  }

  @media (max-width: 512px) {
    width: 100%;
  }
}

.settings-content-header {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 25px;

  .go-back-btn {
    color: $dirty-purple;
    font-family: 'ABCSocialExtended-Regular', sans-serif;
    font-size: 28px;
    font-weight: 600;
    height: fit-content;
    padding-left: 0;

    border: transparent;

    &:hover {
      border: transparent;
    }

    svg {
      width: 15px;
    }
  }

  .save-btn {
    margin-left: auto;
    align-self: flex-end;
    padding-right: 0;

    &:disabled {
      background-color: transparent;
    }
  }

  .subscription-status {
    padding: 3px 15px;
    background-color: $light-gray-3;
    border-radius: 4px;
    color: #fff;
    font-weight: 600;
    line-height: 20px;
    font-size: 14px;
    margin-top: 7px;
    height: 28px;
    display: flex;
    &:before {
      content: '';
      width: 15px;
      margin-right: 10px;
      background: url('../../../../assets/img/icon-x.svg') center center
        no-repeat;
    }
  }
  .subscription-status.active {
    background-color: $green;
    &:before {
      background: url('../../../../assets/img/icon-tick-white.svg') center
        center no-repeat;
    }
  }
  .subscription-status.suspended {
    background-color: #f77200;
    &:before {
      background: none;
      width: 0;
      margin-right: 0;
    }
  }

  @media (max-width: 512px) {
    .go-back-btn {
      font-size: 24px;
      padding-left: 20px;
    }

    .save-btn {
      padding-right: 25px;
    }
  }
}
