.coupons-container {
  .column-coupon {
    border-radius: 16px;
    padding: 40px;
  }

  .coupon-agreement-modal {
    display: flex;
    align-items: center;

    p {
      margin-top: 20px;
    }

    .ant-primary-button {
      margin: 10px;
    }
  }

  .visible-on-mobile {
    @media (max-width: 1200px) {
      display: block;
    }
    @media (min-width: 1200px) {
      display: none;
    }
  }

  .visible-on-desktop {
    @media (max-width: 1200px) {
      display: none;
    }
    @media (min-width: 1200px) {
      display: block;
    }
  }

  .coupon-row {
    border-bottom: 1px solid #cccccc;
    padding: 15px 0;

    &:first-of-type {
      margin-top: 32px;
      border-top: 1px solid #cccccc;
    }

    .ant-row {
      &:first-of-type {
        .ant-col:last-of-type {
          margin-bottom: 16px;
        }
      }

      .ant-col {
        &:first-of-type {
          font-style: italic;
        }
      }
    }
  }

  .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 14px;
  }

  .coupon-code {
    font-weight: 600;
  }

  .used {
    color: rgba(86, 66, 87, 0.35);
  }

  .active {
    color: #008b44;
  }

  .inner-container {
    padding: 25px 30px;
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    background-color: white;
    border-radius: 24px;

    @media (max-width: 600px) {
      //text-align: center;
    }

    table {
      margin-top: 32px;
      width: 100%;
      font-size: 13px;
      line-height: 22px;

      tr {
        border-bottom: 1px solid #cccccc;
      }

      th {
        text-align: left;
        font-style: italic;
        font-weight: 400;
        line-height: 18px;
        color: #564257;
        padding: 6px 0;
      }

      .th-amount {
        display: flex;
        align-content: center;

        img {
          width: 15px;
          margin-left: 5px;
        }
      }

      td {
        padding: 14px 0;
      }
    }

    label {
      margin-top: 30px;
      display: flex;
      align-items: start;
      font-size: 13px;
    }

    .ant-checkbox-inner::after {
      margin-left: 2px;
    }
  }

  .referral-rules {
    margin: 24px 0;
    display: flex;
    justify-content: flex-end;

    span {
      margin: 24px 0;
      cursor: pointer;

      font-family: 'ABCSocialExtended-Regular', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;

      text-decoration: underline;

      color: #061846!important;
    }
  }
}
