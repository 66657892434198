@import '../../colors';

.maintenance-page {
  .btn-logo {
    margin-top: 48px;
    margin-left: 10%;

    img {
      height: 48px;
    }
  }

  .sun-img {
    height: 190px;
  }

  @media (max-width: 768px) {
    .btn-logo {
      margin-top: 29px;
      margin-left: 20px;

      img {
        height: 28px;
      }
    }

    .sun-img {
      height: 124px;
    }
  }

  .maintenance-page-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 46px 10px 53px;

    h1 {
      color: $dirty-purple;
      font-family: Migrena_Grotesque;
      font-size: 24px;
      font-weight: 600;
      line-height: 24px;
      margin: 36px 0 32px;
    }

    p {
      color: $dirty-purple;
      font-family: Migrena_Grotesque;
      font-size: 14px;
      line-height: 19px;
      max-width: 375px;
    }

    .ant-btn {
      min-height: 48px;
      height: fit-content;
      min-width: 256px;
      border-radius: 24px;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.07);
      font-family: Migrena_Grotesque;
      font-size: 16px;
      font-weight: 600;
      line-height: 30px;
      white-space: normal;
      margin-top: 53px;
    }
  }
}
