@import '../../colors';

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #f9f9f9;
}

::-webkit-scrollbar-thumb {
  background: #564257;
}


/* ==========================================================================
Base Elements - Desktop
========================================================================== */
*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
html {
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #061846;
  margin: 0;
}

p {
  color: #564257;
  margin: 0;
  font-size: 18px;
  line-height: 29px;
  font-weight: 500;
}

ol,
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

a {
  text-decoration: none;
  color: #e7792b;
  font-weight: 900;
  transition: 300ms ease all;
}

b,
strong {
  font-weight: 600;
}

.logo {
  margin: auto;
  display: block;
  width: 142px;
}

.sign-up {
  max-width: 700px;
  margin: 72px auto;
  padding: 0 15px;

  @media (max-width: 32em) {
    margin: 48px auto;
  }

  .top {
    text-align: center;

    p {
      font-size: 36px;
      font-weight: 600;
      line-height: 1.33;
      max-width: 640px;
      margin: 136px auto 0;

      @media (max-width: 32em) {
        margin: 44px auto 0;
        font-size: 24px;
      }
    }
  }

  .bottom {
    margin-top: 68px;

    @media (max-width: 32em) {
      margin-top: 36px;
    }

    .sign-up-form {
      text-align: center;
      margin-bottom: 44px;

      .form-email {
        width: 100%;
        max-width: 440px;
        position: relative;
        display: inline-block;
        margin-bottom: 44px;

        &.invalid {
          input {
            border: 2px solid #b00020 !important;
          }
        }
      }

      .ant-primary-button {
        margin-top: 44px;
      }

      input {
        width: 100%;
        border-radius: 27.5px;
        height: 48px;
        border: 2px solid rgb(204, 204, 204);
        font-weight: 600;
        font-size: 16px;
        padding: 16px 20px 21px 16px;
        outline: 0;
        color: #564257;
        transition: 0.25s ease border;

        &:focus {
          border: 2px solid #e7792b;
        }

        &::placeholder {
          color: #cccccc;
        }
      }
    }

    .sing-up-form-terms {
      width: 100%;
      text-align: left;

      .agreements-error {
        color: #b00020;
        position: absolute;
        top: 18px;
        left: 0;
        font-size: 12px;
        font-weight: 600;
      }

      .sing-up-form-note {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        margin-bottom: 28px;
        font-weight: 600;
      }

      a {
        font-weight: 600;
      }

      sup {
        color: #b00020;
        position: absolute;
        left: 24px;
        font-weight: 600;
        font-size: 12px;
      }

      .disclaimer {
        font-size: 9px;
        line-height: 1.33;
        color: #c2c2c2;
      }

      .sign-up-form-group {
        margin-bottom: 20px;

        &:before,
        &:after {
          clear: both;
          display: table;
          content: '';
        }

        &:last-child {
          margin-bottom: 0;
        }

        input {
          display: none;
        }

        label {
          margin: 0;

          p {
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            padding-left: 24px;
          }

          .checkbox-text {
            &--required-mark:before {
              content: '*';
              font-size: 14px;
              color: red;
              margin-right: 5px;
            }
          }
        }
      }

      .sign-up-form-group.invalid {
        input[type='checkbox'] + label {
          span {
            border: 1px solid rgb(234, 22, 80);
          }
        }
      }
    }
  }
}

.auth-form-group {
  position: relative;

  &.invalid {
    input {
      border: 1px solid #b00020 !important;
    }
  }
}

.auth-form-group-inline {
  width: 48%;
  display: inline-block;
  margin-right: 4%;

  @media (max-width: 64em) {
    width: 100%;
    margin-right: 0;
  }
}

.auth-form {
  max-width: 970px;
  border-radius: 4px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  margin: 0 auto 30px;

  .auth-form-top {
    padding: 40px 100px 0;

    @media (max-width: 64em) {
      padding: 20px 20px 0;
    }
  }

  .auth-form-bottom {
    padding: 0 100px 40px;

    @media (max-width: 64em) {
      padding: 0 20px 20px;
    }
  }
}

.login,
.remind,
.register {
  height: 100%;
  min-height: 100vh;
  background: url('../assets/img/sundose-bg.jpg');
  background-size: cover;

  @media (max-width: 64em) {
    padding: 20px;
  }

  .top {
    padding: 68px 0;

    @media (max-width: 64em) {
      padding: 32px 0;
    }

    .logo {
      display: block;
      margin: auto auto 10px;
      width: 142px;
    }

    h3 {
      font-size: 36px;
      font-weight: 600;
      line-height: 1.33;
      text-align: center;
      color: #564257;

      @media (max-width: 64em) {
        font-size: 26px;
        line-height: 1.38;
      }
    }

    p {
      font-size: 15px;
      line-height: 1.6;
      text-align: center;
      max-width: 600px;
      margin: 22px auto 0;
    }
  }

  .auth-form-bottom {
    padding: 20px 75px 40px;

    @media (max-width: 64em) {
      padding: 10px 20px 20px;
    }
  }
}

.register .auth-form-bottom {
  @media (max-width: 64em) {
    padding: 20px;
  }
}

.login,
.remind {
  .auth-form {
    max-width: 570px;
  }
}

.form-header {
  h4 {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    color: #e7792b;

    @media (max-width: 64em) {
      font-size: 18px;
    }
  }

  p {
    text-align: center;
    font-size: 15px;
    line-height: 1.6;
    color: #564257;
    margin-top: 16px;
  }
}

.form-body {
  font-size: 0;
  margin: 20px 0;

  .auth-form-group {
    margin-bottom: 24px;
  }

  .auth-form-group-inline:last-child {
    margin-right: 0;
  }

  .forgot-password {
    text-align: right;
    font-size: 14px;
    font-weight: bold;
    color: #c2c2c2;

    a {
      margin-left: 8px;
    }
  }
}

.btn-link {
  padding-left: 5px;
  font-size: 12px;
}

.terms-of-use-statement {
  text-align: center;
  margin-top: 10px;
}

.form-footer {
  position: relative;

  .sing-up-form-note {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 28px;
    font-weight: 600;
  }

  .registration-data-safety-privacy-policy {
    margin-bottom: 20px;
  }

  .form-terms {
    width: 100%;
    position: relative;

    .agreements-error {
      color: #b00020;
      position: absolute;
      top: -22px;
      left: 0;
      font-size: 12px;
      font-weight: 600;
    }

    .disclaimer {
      font-size: 9px;
      line-height: 1.33;
      color: #c2c2c2;
    }

    .auth-form-group {
      margin-bottom: 20px;

      &:before,
      &:after {
        clear: both;
        display: table;
        content: '';
      }

      &:last-child {
        margin-bottom: 0;
      }

      input {
        display: none;
      }

      label {
        margin: 0;

        p {
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          padding-left: 24px;
        }

        .checkbox-text {
          &--required-mark:before {
            content: '*';
            font-size: 14px;
            color: red;
            margin-right: 5px;
          }
        }
      }
    }

    .auth-form-group.invalid {
      input[type='checkbox'] + label {
        span {
          border: 1px solid rgb(234, 22, 80);
        }
      }
    }
  }
}

.sign-up .bottom .sing-up-form-terms .sign-up-form-group,
.form-footer .form-terms .auth-form-group {
  input[type='checkbox'] + label span {
    width: 15px;
    height: 15px;
    background-color: transparent;
    cursor: pointer;
    border: 1px solid rgba(209, 209, 214, 0.9);
    border-radius: 1px;
    float: left;
    margin-right: 10px;
    position: relative;
    top: 0;
  }

  input[type='checkbox']:checked + label {
    span {
      background-color: #e7792b;
      border: 1px solid #e7792b;

      &:before {
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;
        font-size: 11px;
        color: white;
        content: '\f00c';
        z-index: 60;
      }
    }
  }
}

.pros {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  color: #564257;
  font-weight: 600;
  margin-top: 34px;

  div {
    display: flex;
    align-items: center;
    margin-right: 30px;

    @media (max-width: 64em) {
      display: block;
      margin: 0 0 12px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .pros-icon {
      width: 25px;
      height: 25px;
      display: inline-block;
      margin-right: 8px;
      background-color: #ccc;
      background-size: contain !important;
      background-position: 50% 50% !important;
      background-repeat: no-repeat !important;

      &.pros-icon-01 {
        background: url('../assets/img/included-icon-01.svg');
      }

      &.pros-icon-02 {
        background: url('../assets/img/included-icon-02.svg');
      }
    }
  }
}

.additional-info {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #564257;

  a {
    margin-left: 8px;
  }
}
