@import '../../../../colors';

.box-header-with-button {
  display: flex;
  color: $dirty-purple;
  margin-bottom: 10px;

  .title {
    width: 100%;
    display: flex;
    align-items: center;
    font-family: 'ABCSocialExtended-Medium', sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
  }

  button {
    font-family: ABCSocial-Regular, sans-serif;
    font-size: 14px;
    line-height: 20px;

    &:hover, &:focus {
      border-color: transparent !important;
    }
  }
}

.box-description {
  font-family: ABCSocial-Regular, sans-serif;
  color: $dirty-purple;
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 20px;
}

.add-prescription-modal-body-inner {
  .add-prescription-error-message {
    color: $error-message;
    font-family: ABCSocial-Regular, sans-serif;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin: 15px 0;
  }
}

.action-modal .ant-modal-body .ant-legacy-form-item-label, .action-modal .ant-modal-body .ant-form-item-label {
  font-family: ABCSocialExtended-Regular, sans-serif!important;
}
