@import '../../../scss/layout';
@import '../../../../colors';

.authorization-background__register {
  color: $light-yellow-1;
}

.register-container {
  &--title {
    font-family: ABCSocialExtended-Medium, sans-serif!important;
    font-weight: 500!important;
    font-size: 24px!important;
    line-height: 32px!important;
    color: #061846!important;

    @media (min-width: $md-min-screen-width) {
      font-size: 44px;
      line-height: 48px;
    }
  }

  &--subtitle {
    font-style: normal!important;
    font-family: ABCSocialExtended-Regular, sans-serif!important;
    font-size: 12px!important;
    line-height: 16px!important;
    margin-top: 20px !important;
    color: #061846!important;

    @media (min-width: $md-min-screen-width) {
      font-size: 17px;
      line-height: 24px;
    }
  }

  .ant-alert-message > a {
    color: #3f46c8;
    font-weight: 600;
  }

  .ant-checkbox-wrapper {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
  }

  .ant-form-item-has-error {
    .ant-checkbox-wrapper {
      color: $red-2;
    }
  }

  .checkbox-item {
    .ant-form-item-explain {
      display: none;
    }
  }

  &--extra {
    font-family: ABCSocial-Regular, sans-serif;
    margin-top: 5px !important;
    font-style: normal;
    font-weight: 500;
    font-size: 12px !important;
    line-height: 18px !important;

    @media (min-width: $md-min-screen-width) {
      margin-top: 25px !important;
    }

    & {
      .ant-btn {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        padding-left: 0;
      }
    }
  }

  &--form-extra {
    font-family: ABCSocial-Regular, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px !important;
    line-height: 18px !important;
    margin-top: 16px !important;

    & {
      .ant-btn {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        display: inline;
        padding: 0;
      }
    }
  }
}
