@import '../../../../../../colors.scss';

.subscription-settings {
  margin: 12px;

  @media (max-width: 768px) {
    justify-content: center;
  }

  .active-coupon {
    width: max-content;
    display: flex;
    align-items: center;
    background: #ffffff;
    border-radius: 4px;
    padding: 8px;
    margin: 15px 0;

    font-family: Lato;
    font-style: normal;
    font-size: 14px;
    line-height: 19px;

    color: #42b565;
    span {
      margin-left: 5px;
      color: black;
    }
  }

  .ant-select-selector {
    min-height: 45px;
    border-radius: 6px;
    font-family: Lato;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }

  section {
    background: white;
    padding: 24px;
    border-radius: 32px;
    color: $dirty-purple;

    h2 {
      padding: 19px 30px;
      font-size: 22px;
    }

    button {
      margin: 0;
      padding: 0;
      height: unset;
      line-height: initial;
    }

    & > div {
      padding: 20px 30px 30px;

      .subscription-status-and-payment {
        display: flex;
        justify-content: space-between;
        button {
          padding: 5px 20px;
        }

        @media (max-width: 350px) {
          display: block;
          margin-bottom: 10px;
        }
      }

      p {
        font-family: 'Lato';
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 8px;
      }

      p.next-payment-date {
        @media (min-width: 769px) {
          margin-top: 45px;
        }
      }

      p.subscription-discount {
        color: $green;
        display: inline-flex;
        img {
          margin-left: 10px;
          cursor: pointer;
        }
      }

      .order-status {
        font-family: 'Migrena_Grotesque';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 17px;
        margin-bottom: 18px;
        width: fit-content;
        padding: 5px 15px;
      }

      .subscription-card-prescription {
        padding-top: 30px;
        margin-top: 18px;
        border-top: 1px solid $collapse-header;

        .ant-btn {
          border: transparent!important;
          &:hover, &:focus {
            border: transparent!important;
          }
        }

        a {
          border: transparent!important;
          &:hover {
            border: transparent!important;
          }
        }

        p:first-child {
          display: flex;
          margin-bottom: 12px;
          img {
            width: 19px;
            margin-right: 10px;
          }
        }
      }
    }

    .check-mark-list {
      margin: 20px 0;
      list-style: none;
      padding-left: 7px;
      flex-grow: 1;
      & > li {
        padding-left: 20px;
        text-indent: -30px;

        font-family: 'Lato';
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;
      }
      & > li:before {
        content: '';
        padding-right: 30px;
        background: url('../../../../../assets/img/icon-tick-green.svg') center
          center no-repeat;
      }
    }
  }
}

.subscription-footer {
  margin-top: 20px;
  padding: 15px 27px;
  border-top: 1px solid $light-yellow;

  .ant-btn {
    border: transparent!important;
    &:hover, &:focus {
      border: transparent!important;
    }
  }


  @media (max-width: 768px) {
    margin: 10px 0 50px 0;
    padding: 10px 15px;
  }
}

.text-link {
  color: $dusty-orange;
  text-decoration: underline;
  cursor: pointer;
}

.add-composition, .change-name {
  background-color: #ffffff;
  border-radius: 12px;
  border: 1px solid #061846;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 48px;
  margin: 30px;
  line-height: 1.4;
  text-align: center;
  color: #061846;
  .ant-modal-content {
    box-shadow: none;
  }
  .ant-form-item-label {
    font-family: ABCSocial-Regular, sans-serif!important;
  }
}

.subscription-modal {
  .ant-modal-body {
    box-shadow: none!important;
  }
  .ant-modal-content {
    font-size: 18px;
    background-color: #ffffff;
    border-radius: 12px;
    border: 1px solid #061846;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 48px;
    margin: 30px;
    line-height: 1.4;
    text-align: center;
    color: #061846;
  }

  .ant-modal-body {

    strong {
      font-weight: 600;
    }

    h2,
    h1 {
      font-family: 'ABCSocialExtended-Medium', sans-serif;
      line-height: 32px;
      font-size: 32px;
      font-weight: 600;
      font-style: normal;
      margin-bottom: 35px;
      @media (min-width: 768px) {
        font-size: 36px;
      }
    }

    h3 {
      font-weight: 600;
      line-height: 27px;
    }

    .change-card-header {
      margin: 20px 0;
      font-size: 24px;
      line-height: 34px;
      color: #564357;
    }

    .current-mask {
      font-family: ABCSocial-Regular, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 39px;
      color: #2a2928;
    }

    p {
      font-family: ABCSocial-Regular, sans-serif;
      font-size: 15px;
      font-weight: 500;
      line-height: 26px;
      margin-bottom: 12px;
    }

    h3 > span.required {
      color: #ff4d4f;
    }

    .ant-btn {
      margin-bottom: 10px;
      font-family: 'ABCSocialExtended-Medium', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
    }

    .ant-btn-default {
      color: $dusty-orange;
      border-color: $dusty-orange;
    }

    .ant-checkbox-inner::after {
      left: 30%;
    }

    .ant-form-item {
      margin-bottom: 15px;
    }

    .subscription-modal-discount-row {
      display: flex;
      justify-content: space-between;
      padding: 0.5rem 0;
      border-bottom: 1px solid $gray;

      &:first-of-type {
        margin-top: 20px;
        border-top: 1px solid $gray;
      }

      &:last-of-type {
        margin-bottom: 20px;
      }
      span {
        color: $dark-green;
      }
    }

    .suspend-times {
      display: flex;
      flex-direction: column;
    }

    .ant-form label,
    strong {
      font-size: 15px;
      font-family: ABCSocial-Regular, sans-serif
    }

    .alert {
      padding: 16px;
      background: #ffe2e2;
      border-radius: 10px;
      margin: 15px 0;

      p {
        margin-bottom: 0;
      }
    }

    .info-alert {
      background: #fff1cf;
      margin: 30px 0;
    }

    .p-last {
      margin-top: 25px;
    }
  }
}
