.delivery-settings {
  margin: 12px;
  max-width: 500px;
  padding: 40px;
  border-radius: 16px;
  background-color: white;

  @media (max-width: 768px) {
    justify-content: center;
  }

  .alert {
    margin: 20px 0;
    padding: 16px;
    background: #fff1cf;
    border-radius: 10px;
  }

  .delivery-container {
    width: 100%;
    background: #ffffff;
    border: 1px solid #061846;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 24px;
    margin-top: 10px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    cursor: pointer;
  }

  .box-active {
    background-color: #E6EFFF;
  }

  .dhl-logo {
    width: 52px;
    height: 32px;

    @media (max-width: 768px) {
      width: 42px;
      height: 25px;
    }
  }

  .inpost-logo {
    width: 49px;
    height: 38px;

    @media (max-width: 768px) {
      width: 39px;
      height: 30px;
    }
  }

  .disabled {
    cursor: not-allowed;
  }

  .delivery-info {
    margin-left: 20px;

    h2,
    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      display: block;
    }

    h3 {
      margin: 25px 0 5px 0;
    }

  }

  .ant-btn-primary {
    margin-top: 20px;
  }

  .cc-email {
    font-weight: normal;
    color: inherit;
    text-decoration: underline;
  }

  .form-custom-inline {
    margin-bottom: 0;

    .ant-form-item-control-input-content {
      display: flex;
    }

    .ant-row > .ant-col {
      flex: auto;
    }

    .ant-form-item {
      width: calc(50% - 10px);

      &:first-child {
        margin-right: 20px;
      }
    }
  }

  input,
  .ant-select-selector {
    min-height: 45px;
    border-radius: 6px;
    font-family: Lato;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }

  .checkbox-form-inline-phone {
    .ant-form-item:first-child {
      width: 140px;
      min-width: 140px;
    }
  }

  .phone-codes-dropdown {
    padding: 0 !important;
  }

  .phone-codes {
    color: #bbbbbb;
    font-family: Lato;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;

    .ant-select-selection-item {
      img {
        width: 20px;
        margin-right: 6px;
      }

      .hide-in-selection {
        display: none;
      }
    }
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
}

.phone-codes-option {
  padding: 10px;

  .hide-in-menu {
    display: none;
  }

  img {
    margin-right: 15px;
  }
}

.ant-radio-checked .ant-radio-inner {
  border: 1px solid #061846 !important;
}

.ant-radio-inner::after {
  background-color: #061846 !important;
}

.ant-radio-checked::after {
  border: 1px solid #061846 !important;
}

.panel-style p {
  font-family: ABCSocial-Regular, sans-serif;
}
