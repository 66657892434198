.custom-spinner {
  max-height: none !important;
  position: fixed !important;
}

.custom-spinner-children {
  .ant-spin-blur {
    overflow: inherit;
  }
}
