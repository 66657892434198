@import '../../../../scss/layout';
@import '../../../../../colors';

.authorization-background__reset-password-request {
  color: $light-yellow-1;
}

.reset-password-request-container {
  color: #061846;
  &--title {
    font-family: ABCSocialExtended-Medium, sans-serif!important;
    font-weight: 500!important;
    font-size: 24px!important;
    line-height: 32px!important;
    color: #061846!important;

    @media (min-width: $md-min-screen-width) {
      font-size: 44px;
      line-height: 48px;
    }
  }

  &--subtitle {
    font-style: normal!important;
    font-family: ABCSocialExtended-Regular, sans-serif!important;
    font-size: 12px!important;
    line-height: 16px!important;
    margin-top: 20px !important;
    color: #061846!important;

    @media (min-width: $md-min-screen-width) {
      font-size: 17px;
      font-style: normal !important;
      line-height: 24px;
      margin-top: 19px !important;
    }
  }

  .authorization-form {
    @media (min-width: $md-min-screen-width) {
      margin-top: 45px;
    }
  }

  .reset-password-request-login-button {
    box-shadow: none;
    margin-left: 10px;
  }
}
