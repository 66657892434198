@import '../../../colors';

.order-collapse-body {
  font-family: ABCSocial-Regular, sans-serif;
  color: $dirty-purple;
  display: flex;
  flex-direction: column;

  .order-descriptions {
    font-weight: normal;
    font-size: 16px;
    margin: 5px 0;
    line-height: 24px;

    &:first-child {
      margin-top: 0;
    }
  }

  .font-weight-black {
    margin-left: 5px;
  }
}

.sundose-collapse-header {
  display: flex;

  .collapse-header-container {
    width: 100%;
  }
}

.collapse-header-buttons {
  font-size: 14px;
  font-family: 'Migrena_Grotesque';
  font-weight: 500;
  width: fit-content;

  a,
  button {
    color: white !important;
    font-weight: 500 !important;
    height: 100%;
    display: flex;
    align-items: center;
  }
}

.custom-icon-button-link {
  img {
    position: relative;
    top: 1px;
    margin-right: 5px;
  }
}

.order-button-container {
  margin-top: 15px;

  a {
    margin: 0 auto;
    padding: {
      right: 45px;
      left: 45px;
      top: 4px;
    }
    height: 36px;
    display: flex;
    width: fit-content;
    align-items: center;
    color: white;

    span {
      top: 0 !important;
    }
  }
}

.rounded-button {
  border-radius: 18px;

  &.reorder {
    color: white;
  }
}

.order-delivery-status {
  margin-top: 10px;
}
