@import '../../../../../scss/layout';
@import '../../../../../../colors';

.authorization-background__reset-password-request-success {
  color: $very-light-green;
}

.reset-password-request-success {
  &-container {
    color: $dark-gray !important;

    .send-icon {
      position: absolute;
      right: 0;
      width: 100px;
      top: -100px;
      color: $very-light-green;

      @media (min-width: $md-min-screen-width) {
        width: 144px;
        top: -120px;
      }
    }

    &--title {
      font-family: Migrena_Grotesque;
      font-style: normal;
      font-weight: 600 !important;
      font-size: 32px;
      line-height: 30px;
      margin-top: 20px !important;

      @media (min-width: $md-min-screen-width) {
        margin-top: 0;
      }
    }

    &--subtitle {
      font-family: Metropolis;
      font-style: normal;
      font-weight: 500 !important;
      font-size: 17px;
      line-height: 24px;
      margin-top: 16px !important;
      margin-bottom: 27px !important;

      @media (min-width: $md-min-screen-width) {
        margin-bottom: 35px !important;
      }
    }
  }
}
