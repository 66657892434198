@import '../../scss/layout';
@import '../../../colors';

.auth-wrapper {
  a, button {
    border: transparent;
    &:hover, &:focus {
      border: transparent;
    }
  }
}

.ant-input-affix-wrapper:hover, .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #061846;
}

.ant-btn-link > span {
  font-family: ABCSocialExtended-Regular, sans-serif;
  font-weight: normal;
  color: #67728E;
  &:hover {
    color: #4D576F;
    text-decoration: underline;
  }
}

.n-message {
  width: 100%;
  top: initial;
  bottom: 0;
  position: fixed;

  .ant-message-notice-content {
    max-width: 220px;
    width: 100%;
    text-align: center;
    display: flex;
    margin: 0 auto 35px auto;
    justify-content: center;

    .ant-message-custom-content {
      display: flex;
      align-items: center;
    }
  }

  &-success {
    font-family: ABCSocial-Regular, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;

    .ant-message-notice-content {
      background-color: $light-green-2;
      color: $dark-green;
      box-shadow: none;
      padding: {
        top: 16px;
        bottom: 16px;
      }

      svg {
        top: 1px;
        margin-right: 5px;
        position: relative;
      }
    }
  }
}

.password-submit-button,
.submit-button {
  .ant-form-item-control-input-content {
    justify-content: start;
  }

  .ant-btn {
    border-radius: 4px;
  }
}

.auth {
  &-container {
    width: 100%;
    max-width: $content-max-width;
    margin: 150px auto 0 auto !important;
    height: 100%;
    padding: 0 24px;
    z-index: 1;
    position: relative;

    @media (min-width: $md-min-screen-width) {
      padding: 0;
      margin: 0 auto !important;
      min-height: 100vh;
      display: flex;
      align-items: center;
    }

    .ant-btn-primary {
      background-color: #061846;
      font-family: ABCSocial-Regular, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      padding: 10px 20px;
      height: 45px;
      border-radius: 12px;
      width: auto;
      min-width: auto;

      &:disabled {
        background-color: #BCC2D2;
        color: white;
      }

      &:hover {
        opacity: 0.8;
      }
    }

    .ant-checkbox {
      font-family: ABCSocial-Regular, sans-serif!important;
      &:focus {
        border-color: $accent-color;
      }

      &-checked {
        &:after {
          border-color: $accent-color;
        }

        .ant-checkbox-inner {
          background-color: $accent-color;
          border-color: $accent-color;
        }
      }
    }

    .ant-btn-link {
      color: #67728E;

      &:hover {
        color: #4D576F;
        text-decoration: underline;
      }
    }

    .auth-content-container {
      height: 100%;
      min-height: fit-content;
      z-index: 1;
      background-color: $light-beige;

      @media (min-width: $md-min-screen-width) {
        padding: 0 15px;
      }

      .auth-footer {
        display: flex;
        flex-direction: column;
        margin: 60px 0 80px 0;

        @media (min-width: $md-min-screen-width) {
          flex-direction: row;
          align-items: center;
        }

        &--title {
          font-family: 'ABCSocialExtended-Medium', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 22px;
          color: $dark-gray;

          @media (min-width: $md-min-screen-width) {
            font-size: 17px;
            line-height: 24px;
            padding-top: 5px;
          }
        }

        &--actions {
          font-family: ABCSocialExtended-Regular, sans-serif;
          font-style: normal;

          .ant-btn-link {
            padding: {
              top: 0;
              bottom: 0;
              left: 0;
            }

            @media (min-width: $md-min-screen-width) {
              padding-left: 15px;
            }

            a {
              font-weight: 500;
              font-size: 17px;
              line-height: 20px;

              @media (min-width: $md-min-screen-width) {
                font-size: 19px;
                line-height: 23px;
              }
            }
          }
        }
      }
    }
  }
}
