@import '../../../colors';

.small-confirm-modal {
  .ant-modal-confirm-title {
    color: $dirty-purple;
    font-family: 'ABCSocialExtended-Medium', sans-serif;
    font-size: 20px;
    font-weight: 600;
  }

  .ant-modal-confirm-content {
    color: $dirty-purple;
    font-family: ABCSocial-Regular, sans-serif;
    font-size: 14px;
    font-weight: bold;
  }

  .ant-btn {
    min-height: 36px;
    min-width: 136px;
    font-family: 'ABCSocialExtended-Regular', sans-serif;
    font-size: 16px;
    font-weight: 600;
    border-radius: 24px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.07);
  }

  .ant-modal-confirm-btns {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
}
