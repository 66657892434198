@import '../../../colors';

.prescription-preferences-box {
  text-align: left;
  width: 100%;

  h1 {
    margin-bottom: 6px !important;
  }

  h4 {
    width: max-content;
  }

  strong {
    font-weight: 900 !important;
  }

  .prescription-preferences-box-content {
    width: 100%;
    height: fit-content;
  }

  li {
    padding: 6px 0;
  }

  .ant-list {
    display: block;

    .ant-list-item {
      border-bottom: $snow-white !important;

      .ant-list-item-meta-avatar {
        display: flex;
        justify-content: center;
        width: 20px;

        img {
          height: 100%;
          width: auto;
        }
      }

      .right-col {
        margin-left: 10px;
        white-space: nowrap;
      }
    }
  }

  .flavour-preference-list {
    display: block;

    &--item {
      .ant-list-item-meta-avatar {
        display: none !important;
      }

      .ant-list-item-meta-content {
        margin-left: 21px;
      }
    }
  }

  .flavour-column {
    margin-bottom: 12px;

    img {
      max-height: 21px;
    }

    .flavour-header {
      padding-top: 6px;

      img {
        margin-bottom: 12px;
        margin-right: 5px;
      }
    }

    li {
      padding: 3px 0;

      span {
        margin-left: 20px;
        margin-right: 5px;
      }

      img {
        margin-right: 7px;
      }
    }
  }

  @media (max-width: 550px) {
    h1 {
      font-size: 15px;
    }

    h4 {
      font-size: 14px;
    }

    .flavour-column {
      margin-top: 18px;
    }
  }
}

.prescription-preferences-box-empty-state {
  .info-box {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding-left: 75px;
    padding-right: 75px;
  }

  .ant-btn {
    font-family: 'ABCSocialExtended-Medium', sans-serif;
    font-weight: 500;
  }

  .ant-row {
    height: 100%;
  }

  h1,
  h3,
  .icon-row {
    text-align: center;
    width: 100%;
  }

  img,
  h3 {
    margin-bottom: 20px !important;
  }

  h1 {
    font-size: 19px;
    font-weight: 700;
  }

  h3 {
    font-size: 16px;
  }

  @media (max-width: 512px) {
    .info-box {
    }

    .info-box {
      padding-left: 24px;
      padding-right: 24px;
    }

    h1 {
      font-size: 18px;
    }

    h3 {
      font-size: 14px;
    }
  }
}
