@import '../../../colors';

.ant-collapse-borderless {
  background-color: transparent!important;
}

.sundose-collapse {
  color: $dusty-orange;
  //background-color: rgb(249, 244, 237) !important;

  .ant-collapse-item-active .ant-collapse-header {
    border-bottom: transparent;
  }

  .ant-collapse-content-box {
    border-left: 1px solid $dusty-orange;
    border-right: 1px solid $dusty-orange;
    border-bottom: 1px solid $dusty-orange;
  }

  .sundose-collapse-header {
    border: transparent;
  }

  &-header,
  .ant-collapse-header {
    background-color: #E6EFFF;
    border-radius: 4px !important;
    border: 1px solid $dusty-orange;
    color: $dusty-orange;
  }

  .ant-collapse-header {
    padding: 12px 30px !important;
    display: flex;

    .ant-collapse-arrow {
      top: 16px !important;
      transform: none !important;
      left: 8px !important;
      color: $dirty-purple;
    }

    .sundose-collapse-header {
      font-family: ABCSocial-Regular, sans-serif;
      width: 100%;

      .header-title {
        font-weight: 900;
        font-size: 16px;
      }

      .header-subtitle {
        font-weight: normal;
        font-size: 12px;
      }
    }
  }

  &-body,
  .ant-collapse-content,
  .ant-collapse-content-box {
    background-color: white;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
  }

  .ant-collapse-content-box {
    padding: 15px 30px !important;
  }

  .ant-collapse-item {
    border-bottom: none;
    margin-bottom: 12px;

    &-active {
      margin-bottom: 36px;

      .ant-collapse-header {
        border-radius: 4px 4px 0 0 !important;
      }
    }
  }
}
