.ant-time-picker {
  width: 100%;

  .ant-time-picker-input {
    padding-right: 30px;
  }

  .ant-time-picker-icon {
    position: absolute;
    top: 50%;
    z-index: 2;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    color: rgba(0, 0, 0, 0.65);
    line-height: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 12px;
    width: auto;
    height: auto;
    margin-top: 0;
  }
}
