@import '../../../../scss/layout';
@import '../../../../../colors';

.authorization-background__reset-password-link-expired {
  color: $light-red-1;
}

.reset-password-link-expired {
  color: #061846;
  &-container {
    color: $dark-gray !important;

    .link-expired {
      position: absolute;
      right: 0;
      width: 100px;
      top: -100px;
      color: $light-red-1;

      @media (min-width: $md-min-screen-width) {
        width: 144px;
        top: -120px;
      }
    }

    &--title {
      font-family: ABCSocialExtended-Medium, sans-serif!important;
      font-weight: 500!important;
      font-size: 24px!important;
      line-height: 32px!important;
      color: #061846!important;
      margin-top: 20px !important;

      @media (min-width: $md-min-screen-width) {
        margin-top: 0;
      }
    }

    &--subtitle {
      font-style: normal!important;
      font-family: ABCSocialExtended-Regular, sans-serif!important;
      font-size: 12px!important;
      line-height: 16px!important;
      margin-top: 20px !important;
      color: #061846!important;
      margin-bottom: 27px !important;

      @media (min-width: $md-min-screen-width) {
        margin-bottom: 35px !important;
      }
    }

    &--buttons-container {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      @media (min-width: $md-min-screen-width) {
        flex-direction: row;
        align-items: center;
      }

      .ant-btn {
        font-weight: 500;
        font-family: ABCSocial-Regular, sans-serif;
        font-size: 20px;
        line-height: 24px;
        margin-top: 14px;
        max-width: 220px;
        width: auto;

        @media (min-width: $md-min-screen-width) {
          margin-top: 20px;
        }

        &:first-child {
          @media (min-width: $md-min-screen-width) {
            margin-right: 30px;
          }
        }

        &-link {
          padding: 0;
          text-align: left;
        }
      }
    }
  }
}
