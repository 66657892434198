@import '../../../colors';

.substances-table {
  .ant-table {
    margin-bottom: 34px;

    .title-icon {
      border-radius: 0;
      display: flex;
      justify-content: center;

      img {
        width: unset;
      }
    }

    th {
      background-color: $very-light-yellow;
      color: $dirty-purple;
      font-family: 'ABCSocialExtended-Medium', sans-serif;
      font-size: 16px;
      font-weight: 500;
    }

    td {
      color: $dirty-purple;
      font-family: 'ABCSocialExtended-Regular', sans-serif;
      font-size: 13px;
    }

    .column-name {
      font-size: 16px;
      font-weight: 500;
      text-align: left;
    }

    .column-amount {
      text-align: center;

      strong {
        font-weight: 500;
      }
    }
  }
}
