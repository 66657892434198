@font-face {
  font-family: 'sundose-icons';
  src: url('../../assets/fonts/sundose-icons.eot?5lwcnm');
  src: url('../../assets/fonts/sundose-icons.eot?5lwcnm#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/sundose-icons.ttf?5lwcnm') format('truetype'),
    url('../../assets/fonts/sundose-icons.woff?5lwcnm') format('woff'),
    url('../../assets/fonts/sundose-icons.svg?5lwcnm#sundose-icons')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'sundose-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}

.icon-close:before {
  content: '\e902';
  position: absolute;
  top: 35%;
  left: 38%;
}
.icon-arrow-left:before {
  content: '\e900';
}
.icon-arrow-right:before {
  content: '\e901';
}
