@import '../../../colors';

.affiliation-box {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;

  color: $dirty-purple;
  line-height: 22px;
  letter-spacing: 0;
  text-align: center;

  background-color: white;
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: auto;

  .small-width {
    max-width: 320px;
  }

  .affiliation-box-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 14px;
  }

  .affiliation-box-link {
    font-family: ABCSocial-Regular, sans-serif;
    text-align: center;
    margin-top: 14px;

    a {
      font-size: 14px;
      line-height: 20px;
      color: #67728E;
      &:hover {
        color: #4D576F;
        text-decoration: underline;
      }
    }
  }

  .affiliation-box-description {
    font-family: ABCSocial-Regular, sans-serif;
    font-size: 14px;
    line-height: 20px;
  }

  .affiliation-box-italic {
    font-family: ABCSocial-Regular, sans-serif;
    font-size: 14px;
    line-height: 20px;
    font-style: italic;
    margin-top: 25px;

    & a {
      padding: 0;
      height: 0;
      line-height: 0;
    }
  }

  .affiliation-box-coupon {
    min-height: 60px;
    border-radius: 16px;
    background-color: #E6EFFF;
    font-family: ABCSocial-Medium, sans-serif;
    color: $dusty-orange;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    padding: 15px 20px;
    margin-bottom: 18px;
    span {
      letter-spacing: 0.05rem!important;
    }
  }

  .ant-btn-primary {
    border-radius: 27.5px;
    box-shadow: 0 10px 15px -5px rgba(248, 113, 9, 0.15);
    min-height: 36px;
    min-width: 168px;
    max-width: 100%;
    font-size: 14px;
    font-weight: 600;
    line-height: 30px;
    margin-top: 18px;
  }
}
