@import '../../../../colors';

.language-picker {
  .custom-picker-title {
    p {
      margin: 0 0 0 10px;
      align-self: baseline;
    }

    .anticon-global {
      color: $light-gray-2;
    }
  }
}
