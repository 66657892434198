@import '../../../scss/layout';
@import '../../../../colors';

.auth {
  &-wrapper {
    background: $light-beige;
    height: 100%;
    min-height: 100vh;
    position: relative;
    display: flex;

    &--logo-wrapper {
      position: absolute;

      img {
        width: 20%;
      }
    }

    .authorization-background {
      position: absolute;
      left: 0;
      width: auto;

      &--top-mobile {
      }

      &--bottom-mobile {
        bottom: 0;
        left: auto;
        right: 0;
      }

      &--desktop {
        height: 100%;
        position: fixed;
        top: 0;
      }
    }
  }
}
