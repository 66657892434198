@import '../../../scss/layout';

.quick-register-page-v3 {
  min-height: 100vh;
  padding: 0 24px;

  &__header {
    display: flex;
    padding: 35px 0 0;
    flex-direction: column;
    align-items: center;
    .title {
      margin: 12px 0;
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      @media (max-width: $md-max-screen-width) {
        margin: 5px 0;
        font-size: 14px;
      }
    }
  }

  &__content {
    margin: 55px auto 0;
    max-width: 569px;
    @media (max-width: $md-max-screen-width) {
      max-width: 100%;
      margin: 30px auto 0;
    }
    .title {
      text-align: center;
      font-size: 20px;
      line-height: 28px;
      font-weight: 500;
      margin: 0 0 20px;
      font-family: 'ABCSocialExtended-Medium', sans-serif;
      color: #061846;
      @media (max-width: $md-max-screen-width) {
        font-size: 28px;
        line-height: 34px;
      }
    }

    .go-exp {
      font-weight: bold;
      font-size: 28px;
      line-height: 34px;
      text-align: center;
      color: #2A2928;
    }

    .social-media-buttons {
      @media(min-width: 450px) {
        display: none;
      }

      padding-top: 20px;

      button {
        max-width: inherit;
        margin-bottom: 10px;
        border-radius: 4px;
        height: 48px;
        color: white;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;

        &:last-of-type {
          margin-bottom: 30px;
        }

        &.facebook {
          background: #1877f2;
        }
        &.google {
          color: #505050;
        }
        &.apple {
          background: #000000;
        }
      }

      .with-line {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
          width: 100%;
          height: 1px;
        }

        span {
          font-size: 14px;
          line-height: 14px;
          color: #737373;
        }
      }
    }
  }
}
