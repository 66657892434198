@import '../../scss/layout';
@import '../../../colors';

.authorization-form {
  .ant-form-item {
    margin-top: 15px;

    &-has-error {
      .ant-form-item {
        &-label {
          color: $red-2;
        }

        &-control {
          .ant-form-item-explain {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 14px;
            margin-top: 10px;
          }

          &-input {
            input {
              border-color: $red-2;
              color: $red-2;
            }
          }
        }
      }
    }

    @media (min-width: $md-min-screen-width) {
      margin-top: 21px;
    }

    &-control {
      &-input {
        input {
          height: 44px;
          border-radius: 8px;
          background: white;
          padding: {
            top: 14px;
            left: 14px;
            bottom: 10px;
            right: 14px;
          }
          border-color: white;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;

          &:hover,
          &:active,
          &:focus {
            background: white;
          }
        }
      }
    }

    &-label {
      label {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 14px;
        color: black;
      }
    }

    .ant-checkbox-wrapper.required {
      & > span:last-child {
        &::before {
          content: '*';
          position: absolute;
          left: 7px;
        }
      }
    }

    .ant-checkbox-wrapper {
      display: flex;

      & > span:last-child {
        padding-left: 15px;
        position: relative;
      }

      .ant-checkbox {
        top: 5px;
        width: 20px;
        height: 20px;

        &-inner {
          width: 20px;
          height: 20px;

          &:after {
            left: 25%;
          }
        }
      }
    }
  }
}
