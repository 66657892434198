.infobox-with-modal {
  padding: 24px;
  background: white;
  border-radius: 24px;

  .info-icon {
    margin-left: 10px;
    cursor: pointer;
  }
}

.infobox-modal > .ant-modal-content > .ant-modal-body {
  display: flex;
  flex-direction: column;

  strong {
    margin: 10px 0;
    display: block;
  }

  span {
    margin: 5px 0;
    display: block;
  }
}
