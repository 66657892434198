@import '../colors.scss';
@import '../fonts.scss';

body {
  background-color: #F2EDF0;
  font-family: 'ABCSocialExtended-Medium', sans-serif !important;
}

#root {
  height: 100%;
}

.survey-style {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    color: #061846;
    font-weight: 600;
  }


  p {
    margin: 0;
    font-weight: 500;
    color: #564257;
    font-size: 19px;
    line-height: 29px;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  a {
    color: #e7792b;
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
  }

  .btn-multi-flex {
    display: flex !important;
    align-items: center !important;
    justify-content: space-around !important;

    color: #061846;
    background: none;
    border: none;

    span {
      max-width: 110px !important;
    }
  }

  .multi-wrap {
    cursor: pointer;
    display: flex;
    .multi-check {
      margin-right: 15px;
    }
    .multi-text {
      text-align: left;
    }
  }

  .multi-btn {
    text-align: center;
    margin-top: 60px;
  }

  .new-btn-full {
    border: none!important;
    font-weight: 400;
    letter-spacing: 0.02em;
    font-family: ABCSocial-Regular, sans-serif;
    color: #061846!important;
    background:none!important;
    font-size: 12px!important;
    line-height: 16px!important;

    .check {
      i {
        font-size: 20px;
        width: 19px;
      }
      .text {
          text-align: left;
      }
    }
  }

  .btn-full {
    color: #fff;
    background: #FF8941;
    -webkit-appearance: media-slider!important;
    border-radius: 12px;
  }

  .btn-ghost {
    background: #061846;
    border-radius: 12px;
    color: #fff;
    cursor: pointer;
    font-weight: normal;
    -webkit-appearance: media-slider!important;
    letter-spacing: 1px;
  }

  .arrow {
    color: #67728E;
  }

  #terminal {
    overflow: hidden;
    height: calc(100vh);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #F2EDF0;
  }

  #terminal h3 {
    font-family: "ABCSocialExtended-Medium", sans-serif;
    color: #061846;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    padding: 0 32px;
    max-width: 1280px;
    margin: auto;
    text-align: center;
    opacity: 0;
  }

  @media (max-width: 64em) {
    .inner-term {
      //margin-top: 100px;
    }

    #terminal h3 {
      margin: 0 auto 0!important;
    }

    #terminal h3 {
      font-size: 21px;
      line-height: 34px;
      margin: 44px auto 0;
      position: relative;
    }
  }
  .multi-btn {
    margin-top: 17px!important;
  }
  #terminal.multi_option h3 {
  }

  #answers {
    margin-top: 64px;
    //justify-content: center;
    //background-color: #F2EDF0;
    //padding: 24px;
    //position: fixed;
    //bottom: 0;
    //left: 0;
    //width: 100%;
    //display: flex;
    //align-items: center;
    //flex-wrap: wrap;
    //transform: translateY(100%);
    //border-top: 1px solid #C5B5BE;
  }

  #answers .btn {
    display: inline-block;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    outline: 0;
    cursor: pointer;
    width: 100%;
    position: relative;
    min-height: 40px;
    //padding: 0 15px 3px;
    padding: 10px 5px;
    overflow-wrap: break-word;
    border-radius: 12px;
    font-weight: 400;
    letter-spacing: 0.02em;
    font-family: ABCSocial-Regular, sans-serif;


    @media (max-width: 64em) {
      min-width: 120px;
    }
  }

  #answers .wrapper {
    max-width: 900px;
    justify-content: center;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
  }

  #answers .form-answer {
    position: relative;
    flex: 1 1;
    width: 50%;
    margin: 10px;
    min-width: 150px;

    @media (max-width: 32em) {
      min-width: 100%;
    }

    .btn {
      height: 100%;
    }
  }

  #answers .form-answer .btn .check {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 18px;
    width: 6px;
    text-align: center;
    -webkit-transition: all 333ms ease;
    transition: all 333ms ease;

    i {
      position: relative;
      top: 0px;
    }
  }

  #answers .btn.new-send-answers {
    margin: 8px;
    height: 44px;
    position: unset!important;
    width: auto;

    @media (max-width: 36em) {
      margin: 8px 0;
    }
  }

  #answers .btn.send-answers {
    width: 100%;
    border-radius: 27px;
    background-color: #fff;
    color: #e7792b;
    // line-height: 26px;
    border: 2px solid #e7792b;
    outline: 0;
    margin: 8px;
    height: 44px;

    @media (max-width: 36em) {
      margin: 8px 0;
    }
  }

  #intro_stage,
  #outro_stage {
    opacity: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  #intro_stage .wrap,
  #outro_stage .wrap {
    display: flex;
    max-width: 900px;
  }

  #intro_stage .left_column,
  #outro_stage .left_column {
    margin-right: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  #intro_stage h2,
  #outro_stage h2 {
    color: #061846;
    font-size: 20px;
    font-family: "ABCSocialExtended-Medium", sans-serif;
    font-weight: 500;
    line-height: 28px;
  }

  #intro_stage p,
  #outro_stage p {
    max-width: 640px;
    margin-bottom: 44px;
  }

  #intro_stage button,
  #outro_stage button {
    max-width: 200px;
  }

  #intro_stage .intro_image_wrapper,
  #outro_stage .intro_image_wrapper {
    max-width: 320px;
    margin: 0 auto 44px;
  }

  #intro_stage .intro_image_wrapper img,
  #outro_stage .intro_image_wrapper img {
    width: 100%;
    display: block;
    border-radius: 12px;
    margin: auto;
  }

  @media (max-width: 64em) {
    #intro_stage .left_column,
    #outro_stage .left_column {
      display: none;
    }

    #intro_stage .intro_image_wrapper img,
    #outro_stage .intro_image_wrapper img {
      max-width: 280px;
    }

    #intro_stage .intro_image_wrapper,
    #outro_stage .intro_image_wrapper {
      margin: 0;
    }
  }

  #intro_stage h3,
  #outro_stage h3 {
    text-align: center;
    color: #e7792b;
    margin: 8px 0;
  }

  /* Inputs */
  #answers .form-answer input[type='number'],
  #answers .form-answer input[type='text'],
  #answers .form-answer input[type='email'] {
    outline: none;
    border: 1px solid #DDE1E9;
    border-radius: 12px;
    background-color: #fff;
    padding: 12px 15px 10px 15px;
    color: #564257;
    font-size: 14px;
    font-weight: normal;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    min-width: 450px;
    font-family: 'ABCSocialExtended-Medium', sans-serif;
  }

  #answers .btn-confirm {
    color: #fff;
    padding: 7px 10px 5px 10px;
    background: #061846;
    border-radius: 12px;
    font-weight: 400;
    letter-spacing: 0.02em;
    font-family: ABCSocial-Regular, sans-serif;
    position: absolute;
    top: 6px;
    right: 6px;
    -webkit-appearance: media-slider!important;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
  }

  #header {
    background-color: #F2EDF0;
    height: 130px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    border-bottom: 1px solid #c5b5be;
  }

  #header h1 {
    color: #061846;
    font-size: 16px;
    font-family: "ABCSocialExtended-Medium", sans-serif;
    line-height: 24px;
    text-align: center;
    flex-grow: 1;
    font-weight: 500;
  }

  @media (max-width: 64em) {
    #answers {
      margin-top: 15px!important;
    }

    #header-title-wrapper {
      margin: 15px auto 15px auto!important;
    }

    #header {
      height: 100px;
    }

    #header h1 {
      font-size: 15px;
    }
  }

  @media (max-width: 32em) {
    .hide-mobile {
      display: none;
    }
  }

  #header-title-wrapper {
    display: flex;
    height: auto;
    max-width: 1920px;
    padding: 0 32px;
    margin: 32px auto 15px auto;
    width: 100%;
    align-items: center;

    .skip-survey {
      right: 32px;
      top: 30px;
      position: absolute;
      display: flex;
      align-self: flex-end;
      color: orange;

      span {
        font-family: 'ABCSocialExtended-Medium', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 18px;
        color: #DA7E3F;
        margin-left: 15px;

        &:hover {
          cursor: pointer;
        }
      }

      @media (max-width: 64em) {
        top: 25px;

        span {
          font-size: 15px;
          margin-left: 8px;
        }
      }
    }
  }

  #header .wrapper {
    width: 100%;
    height: 100%;
    max-width: 1280px;
    margin: -20px auto;
    padding: 0 32px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;

    .progress-wrapper {
      display: flex;
      width: 100%;
      align-content: center;

      .left-margin {
        margin-left: 36px;
      }

      .right-margin {
        margin-right: 36px;
      }

      .btn-link {
        font-family: 'ABCSocialExtended-Medium', sans-serif;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 20px;
        height: 70px;
        color: #061846;
        background: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;

        #arrow-left {
          transform: rotate(-180deg);
        }

        .arrow {
          width: 7px;
          height: 11px;
          object-fit: contain;
          margin: 0 10px;
        }

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }

      #progress {
        width: 100%;
        max-width: 707px;
        margin: 0 auto;
        height: 8px;
        border-radius: 8px;
        position: relative;
        overflow: hidden;
        align-self: center;
      }

      #progress .line {
        width: 100%;
        height: 8px;
        position: absolute;
        left: 0;
        background-color: #D8CAD2;
      }

      #progress .line-progress {
        width: 0%;
        height: 8px;
        position: absolute;
        left: 0;
        border-radius: 8px;
        background-color: #2AB180;
        transition: 0.25s ease all;
      }
    }
  }

  @media (max-width: 64em) {
    #answers {
      padding: 16px;
    }

    #answers .form-answer input[type='number'],
    #answers .form-answer input[type='text'],
    #answers .form-answer input[type='email'] {
      min-width: 100%;
    }

    .btn {
      padding: 0;
      font-size: 13px;
      min-width: 0;
    }

    #answers {
      display: flex;
      flex-wrap: wrap;
    }

    #answers .form-answer {
      flex: 1 0 33%;
      min-width: auto;
      margin: 4px;
    }

    #answers .form-answer {
      width: 100%;
    }
  }

  .error-not-found,
  .error-not-authorized {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }

  .error-not-found h1,
  .error-not-authorized h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    font-size: 96px;
    color: #292a2f;
    width: 100%;
    max-width: 800px;
  }

  .menu-button {
    border: 0;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    padding: 4px;
    background-color: initial;
    cursor: pointer;
    outline: 0;
  }

  .menu-button img {
    height: 100%;
    display: block;
    margin: auto;
  }

  #menu {
    position: fixed;
    top: 70px;
    left: 0;
    width: 100vw;
    height: calc(100vh - 70px);
    margin: 0;
    z-index: 99999999;
    background-color: #fff;
    overflow-y: scroll;
  }

  #menu::-webkit-scrollbar {
    display: none;
  }

  #questionDetails {
    margin-top: 44px;
  }

  #questionDetails .wrapper {
    width: 100%;
    max-width: 640px;
    margin: auto;
  }

  #questionDetails .headline {
    margin: 0;
    box-shadow: inset 0 -0.5px 0 0 hsla(0, 0%, 55.7%, 0.2);
    padding: 24px 16px 36px;
    color: #36393e;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
  }

  .question-list li {
    box-shadow: inset 0 -0.5px 0 0 hsla(0, 0%, 55.7%, 0.2);
    padding: 24px 0;
    display: flex;
    justify-content: space-between;
  }

  .question-list li span {
    color: #36393e;
    font-size: 17px;
    line-height: 22px;
    margin-bottom: 12px;
    display: block;
  }

  .question-list li p {
    font-size: 16px;
    line-height: 22px;
  }

  .question-list li button {
    border: 0;
    background-color: initial;
    color: #e7792b;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
    font-family: 'ABCSocialExtended-Medium', sans-serif;
    outline: none;
  }

  .survey-error {
    position: relative;

    .wrapper {
      max-width: 1170px;
      margin: auto;
    }

    .row:before,
    .row:after {
      content: '';
      display: table;
      clear: both;
    }

    .col-wide {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
    }

    .sundose-sun-image {
      width: 340px;
      display: block;
      position: fixed;
      top: 0;
      left: 50%;

      @media (max-width: 64em) {
        width: 150px;
        left: 60%;
      }
    }

    .btn-logo {
      display: inline-block;
      margin-top: 48px;
      width: 140px;

      @media (max-width: 64em) {
        margin-top: 32px;
      }

      img {
        width: 100%;
        display: block;
      }
    }

    .shape-small {
      width: 95px;
      display: block;
      position: absolute;
      top: 190px;
      left: 0;

      @media (max-width: 64em) {
        top: 130px;
        width: 60px;
      }
    }

    .shape-big {
      width: 230px;
      display: block;
      position: absolute;
      bottom: 305px;
      right: 0;

      @media (max-width: 64em) {
        display: none;
      }
    }

    .shape-wave {
      width: 380px;
      display: block;
      position: absolute;
      bottom: 220px;
      left: 0;

      @media (max-width: 64em) {
        width: 230px;
        left: 50%;
        bottom: -50%;
        transform: translate(-50%, -50%);
      }
    }

    .survey-error-wrapper {
      position: relative;
      width: 100%;
      height: 100vh;

      @media (max-width: 64em) {
        height: auto;
      }
    }

    .survey-error-content {
      padding-top: 190px;

      @media (max-width: 32em) {
        padding-top: 70px;
      }

      p {
        font-size: 24px;
        line-height: 1.5;
        max-width: 560px;
        margin: auto;

        @media (max-width: 64em) {
          line-height: 1.25;
          max-width: 270px;
        }
      }

      .top {
        text-align: center;

        span {
          font-size: 80px;

          @media (max-width: 64em) {
            font-size: 55px;
          }
        }

        b {
          font-size: 119px;
          font-weight: 500;
          color: #564257;
          margin-left: 18px;

          @media (max-width: 64em) {
            font-size: 80px;
          }
        }
      }

      .bottom {
        text-align: center;
        margin-top: 44px;

        p {
          max-width: 490px;
          margin: auto;
        }

        .button {
          display: inline-block;
          width: 270px;
          height: 60px;
          font-size: 16px;
          font-weight: 600;
          color: $dusty-orange;
          box-shadow: 0 10px 15px -5px rgba(0, 0, 0, 0.1);
          background-color: #ffffff;
          border-radius: 30px;
          line-height: 57px;
        }
      }
    }
  }
}
