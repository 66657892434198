@import '../../../../colors';

.offer-card-container {
  padding: 40px;
  background: white;
  border-radius: 16px;
}

.offer-view {
  display: flex;
  flex-direction: column;
  padding: 0 20px 40px 20px;

  .offer-for-you-header {
    color: $dirty-purple;
    font-family: 'ABCSocialExtended-Medium', sans-serif;
    font-size: 28px;
    font-weight: 600;
    line-height: 36px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 512px) {
      font-size: 24px;
    }
  }

  .offer-for-you-description {
    color: $dirty-purple;
    font-family: ABCSocial, sans-serif;
    font-size: 15px;
    margin-bottom: 10px;
  }

  .offer-left-col {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    img {
      height: auto;
      max-width: 100%;
      object-fit: contain;
      border-radius: 16px;

      @media (max-width: 768px) {
        margin: 25px 0;
        max-width: 40%;
      }

      @media (max-width: 512px) {
        max-width: 35%;
      }
    }
  }

  .info-box-with-list {
    margin-top: 15px;
  }

  .offer-warranties {
    margin-top: 70px;
    display: flex;
    justify-content: space-between;
  }

  .offer-price-parts,
  .offer-action-price {
    padding: 0 20px;

    @media (max-width: 512px) {
      padding: 0;
    }
  }

  .offer-price-row {
    color: $dirty-purple;
    font-family: 'ABCSocialExtended-Regular', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    span {
      font-family: ABCSocial, sans-serif;
    }

    @media (max-width: 512px) {
      font-size: 15px;
    }
  }

  .offer-summary {
    .offer-summary-header {
      color: $dirty-purple;
      font-family: 'ABCSocialExtended-Medium', sans-serif;
      font-size: 19px;
      font-weight: 600;
      line-height: 36px;
    }

    .summary-box {
      margin: 15px 0 25px;

      .summary-box-header {
        background-color: #E5EFFF;
        border: 1px solid #061846;
        border-bottom: transparent;
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 24px;
        color: $dirty-purple;
        font-family: 'ABCSocialExtended-Regular', sans-serif;
        font-size: 18px;
        font-weight: 600;

        .ant-btn-link {
          font-family: ABCSocial, sans-serif;
          font-size: 12px;
          padding-right: 0;
          border: transparent;

          &:hover {
            border: transparent;
          }

          a {
            display: flex;
            align-items: center;
            color: #67728E;

            &:hover {
              color: #4D576F;
              text-decoration: underline;
            }
          }
        }
      }

      .summary-box-body {
        background-color: #fff;
        border-bottom: 1px solid #061846;
        border-right: 1px solid #061846;
        border-left: 1px solid #061846;
        border-bottom-right-radius: 12px;
        border-bottom-left-radius: 12px;

        padding: 20px 30px;
        display: flex;
        align-items: center;

        img {
          margin-right: 10px;
          height: 20px;
        }

        span {
          color: $dirty-purple;
          font-family: ABCSocial, sans-serif;
          font-size: 16px;
        }
      }
    }
  }

  .info-box {
    background-color: $light-yellow;
  }

  .prescription-preferences-box {
    @media (max-width: 1200px) {
      .prescription-preferences-box-content {
        flex-direction: column;
      }

      .ant-col {
        max-width: 100%;
        width: 100%;
        margin: 0;
      }

      .flavour-column {
        margin-top: 18px;
      }
    }

    @media (max-width: 512px) {
      .prescription-preferences-box-content {
        flex-direction: row;
      }

      .ant-col:first-child {
        .ant-list {
          width: 100%;
        }
      }
    }
  }

  .offer-action-price {
    margin: 35px 0;
  }

  .offer-price-summary {
    display: flex;
    font-family: Lato;
    color: $dirty-purple;
    font-weight: bold;
    align-items: center;

    .offer-price-summary-description {
      font-size: 20px;
      line-height: 24px;
      font-family: 'ABCSocialExtended-Regular', sans-serif;

      @media (max-width: 512px) {
        font-size: 18px;
        font-weight: 600;
        line-height: 26px;
      }
    }

    .offer-price-summary-price-row {
      font-family: ABCSocial, sans-serif;
      flex: 1;
      text-align: right;
      font-size: 14px;
      font-weight: 500;
      margin-left: 10px;
      strong {
        font-size: 33px;
        line-height: 40px;
        white-space: nowrap;
        margin-left: 10px;
      }
      s {
        margin-left: 5px;
        white-space: nowrap;
      }

      @media (max-width: 512px) {
        strong {
          font-size: 28px;
          font-weight: 900;
          line-height: 34px;
        }
      }
    }
  }

  .offer-action-buttons {
    display: flex;
    text-align: center;
    margin-top: 30px;

    @media (max-width: 512px) {
      flex-direction: column;

      .subscription-status-active {
        justify-content: center!important;
      }

      .ant-btn, .subscription-status-active {
        margin: 15px!important;
        padding: 10px 16px!important;
      }
      .ant-btn:last-child {
         align-self: normal!important;
        margin-top: 15px!important;
      }
    }

    .ant-btn {
      font-family: 'ABCSocialExtended-Medium', sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 30px;
      min-height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;

      &-primary:not(.ant-btn-background-ghost) {
        color: $snow-white;
      }

      &:first-child {
        margin-right: 20px;
        font-size: 14px;
        flex: 2;
      }

      &:last-child {
        margin-left: 20px;
        align-self: flex-end;
        flex: 3;
      }

      @media (max-width: 512px) {
        &:first-child {
          margin-right: 10px;
        }

        &:last-child {
          margin-left: 10px;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }

    .subscription-status-active {
      padding: 10px 35px;
      background-color: $green;
      border-radius: 12px;
      color: #fff;
      font-weight: 500;
      line-height: 16px;
      font-size: 14px;
      min-height: 36px;
      display: flex;
      align-items: center;

      @media (max-width: 512px) {
        padding: 10px 10px;
        &::before {
          width: 10px;
          margin-right: 5px;
        }
      }
    }
  }

  .subscription-dates-and-settings {
    font-family: 'ABCSocialExtended-Regular', sans-serif;
    text-align: right;
    margin-top: 10px;
    a {
      margin-left: 10px;
      color: #67728E;
      font-weight: 500;
      @media (max-width: 512px) {
        display: block;
      }
      &:hover {
        color: #4D576F;
        text-decoration: underline;
      }
    }
  }

  .offer-payment-methods {
    .offer-payment-methods-wrapper {
      width: fit-content;
      margin-right: 0;
      margin-left: auto;
      margin-top: 35px;

      img {
        margin: -25px 7px 0 7px;
      }

      @media (max-width: 512px) {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 20px;
        padding-left: 20px;
      }
    }
  }

  .omega-3-info-box {
    border-radius: 4px 4px 0 0;
    background-color: $light-yellow;
    margin-bottom: 14px;
    padding: 17px 20px;

    h3 {
      color: $dirty-purple;
      font-family: Migrena_Grotesque;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 18px;
      margin-bottom: 14px;
    }

    .ant-btn-primary {
      box-sizing: border-box;
      min-height: 36px;
      border-radius: 27.5px;
      font-family: Migrena_Grotesque;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 30px;
      color: $snow-white;

      span {
        top: 0;
      }
    }

    p {
      color: $dirty-purple;
      font-family: Lato;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 17px;
      margin-top: 14px;

      b {
        font-weight: bold;
      }

      a {
        font-weight: normal;
      }
    }
  }
}

// quick fix to make header menu clickable when loading:
.ant-spin-nested-loading .custom-spinner {
  margin-top: 100px;
  height: calc(100% - 100px) !important;
}
