@import '../../../../colors';

.settings-view {
  display: flex;
  padding-bottom: 15px;
  margin-top: -24px;
  min-height: calc(100vh - 144px);
  width: 100%;
  padding-top: 28px;

  @media (max-width: 512px) {
    min-height: calc(100vh - 66px);
    background-color: $very-light-yellow;
    width: 100%;
  }

  .ant-btn-link {
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
  }
}

.col-padding {
  padding: 0 10px;
}
